import React, { useEffect, useState } from 'react';
import ActivityCard from '../components/ActivityCard/ActivityCard';
import ActivityDetailCard from '../components/ActivityDetailCard/ActivityDetailCard';
import { deleteRequest, postRequest } from '../services/ApiService';
import { CATEGORYPREGANENTWOMEN } from '../libs/Constants';
import Loader from 'react-js-loader';
import BackArrow from '../assets/images/backbutton.png';
import DeleteModal from '../components/Modal/DeleteModal';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

export default function ActivityDetail(props) {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const activityId = props.location.id;
 const [tabs, setTabs] = useState({
  tabOne: true,
  tabTwo: false,
 });
 const [isModal, setIsModal] = useState(false);
 const [activityList, setActivityList] = useState([]);
 const [loading, setLoading] = useState(false);
 const [isConfirmation, setIsConfirmation] = useState(false);

 function handleClick() {
  history.push('./activityContent');
 }
 const defaultStateTabs = {
  tabOne: false,
  tabTwo: false,
 };
 const tabSwitch = (name) => {
  setTabs({ ...defaultStateTabs, [name]: true });
 };

 const getActivityDetailData = async () => {
  console.log('ID', activityId);
  setLoading(true);
  const response = await postRequest('/admin/activities/details/get', {
   ActivityId: activityId,
  });
  if (response) {
   console.log(JSON.stringify(response.result[0]));
   setActivityList(response.result[0]);
   setLoading(false);
  } else {
   setLoading(false);
  }
 };
 const deleteData = async () => {
  const response = await deleteRequest('admin/activities/delete', {
   ActivityId: activityId,
  });
  if (response) {
   console.log(response);
   if (response.msg === 'success') {
    setIsConfirmation(true);
    setIsModal(false);
    getActivityDetailData();
   } else {
   }
  } else {
  }
 };

 useEffect(() => {
  getActivityDetailData();
 }, []);

 return (
  <div>
   <div className="topBarContainer">
    <div className="container">
     <div className="row viewForumTop">
      <div className="col-md-6 searchContainer">
       <div className="backButton">
        <a href="./activityContent">
         <img src={BackArrow} alt="Back Arrow" className="backArrow" />
        </a>
       </div>
      </div>
      <Button
       topBarButton={true}
       smallButton={true}
       buttonOnClick={() => setIsModal(true)}
       LableTxt="Delete"
      />
     </div>
    </div>
   </div>
   <div className="container">
    <div className="row">
     <div className="col-md-2">
      <button
       onClick={() => tabSwitch('tabOne')}
       className={tabs.tabOne ? 'tab active' : 'tab'}
      >
       English
      </button>
     </div>
     <div className="col-md-2">
      <button
       onClick={() => tabSwitch('tabTwo')}
       className={tabs.tabTwo ? 'tab active' : 'tab'}
      >
       Hindi
      </button>
     </div>
    </div>
   </div>
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <Loader type="bubble-top" bgColor={'#005190'} size={52} />
     </div>
    </div>
   ) : (
    <div className="container">
     {tabs.tabOne && (
      <>
       {activityList.map((item, i) => {
        return (
         <>
          <ActivityCard
           language="English"
           category={item.category}
           category_e={
            item.category === CATEGORYPREGANENTWOMEN
             ? 'Pregnant Women'
             : 'Parents'
           }
           tag={item.tagid}
           tag_e={item.tag_en}
           title_e={item.title_en}
           image_e={item.activity_image_en}
           agegroupId={item.agegroup_id}
           agegroup={item.agegroup}
           actionType="Update"
           path="./activityUpdate"
           id={item.activity_id}
           task_e={item.task_en}
           explanation_e={item.explanation_en}
           imageDetail_e={item.image_en}
           soundTask_e={item.sound_task_en}
           soundExplanation_e={item.sound_explanation_en}
           category_h={
            item.category === CATEGORYPREGANENTWOMEN ? 'गर्भवती औरत' : 'अभिभावक'
           }
           tag_h={item.tag_h}
           title_h={item.title_h}
           image_h={item.activity_image_h}
           task_h={item.task_h}
           explanation_h={item.explanation_h}
           imageDetail_h={item.image_h}
           soundTask_h={item.sound_task_h}
           soundExplanation_h={item.sound_explanation_h}
          />
          <ActivityDetailCard
           language="English"
           task={item.task_en}
           explanation={item.explanation_en}
           image={item.image_en}
           soundTask={item.sound_task_en}
           soundExplanation={item.sound_explanation_en}
           id={item.activity_id}
           title_e={item.title_en}
           title_h={item.title_h}
           image_e={item.activity_image_en}
           category={item.category}
          />
         </>
        );
       })}
      </>
     )}
     {tabs.tabTwo && (
      <>
       {activityList.map((item, i) => {
        return (
         <>
          <ActivityCard
           language="Hindi"
           category={item.category}
           category_e={
            item.category === CATEGORYPREGANENTWOMEN
             ? 'Pregnant Women'
             : 'Parents'
           }
           tag={item.tagid}
           tag_e={item.tag_en}
           title_e={item.title_en}
           image_e={item.activity_image_en}
           agegroupId={item.agegroup_id}
           agegroup={item.agegroup}
           actionType="Update"
           path="./activityUpdate"
           id={item.activity_id}
           task_e={item.task_en}
           explanation_e={item.explanation_en}
           imageDetail_e={item.image_en}
           soundTask_e={item.sound_task_en}
           soundExplanation_e={item.sound_explanation_en}
           category_h={
            item.category === CATEGORYPREGANENTWOMEN ? 'गर्भवती औरत' : 'अभिभावक'
           }
           tag_h={item.tag_h}
           title_h={item.title_h}
           image_h={item.activity_image_h}
           task_h={item.task_h}
           explanation_h={item.explanation_h}
           imageDetail_h={item.image_h}
           soundTask_h={item.sound_task_h}
           soundExplanation_h={item.sound_explanation_h}
          />
          <ActivityDetailCard
           language="Hindi"
           task={item.task_h}
           explanation={item.explanation_h}
           image={item.image_h}
           soundTask={item.sound_task_h}
           soundExplanation={item.sound_explanation_h}
           id={item.activity_id}
           title_e={item.title_en}
           title_h={item.title_h}
           image_e={item.activity_image_en}
           category={item.category}
          />
         </>
        );
       })}
      </>
     )}
    </div>
   )}
   {isModal && (
    <>
     <DeleteModal
      onClick={() => setIsModal(false)}
      DeleteOnclick={() => deleteData()}
      CancelOnclick={() => setIsModal(false)}
      modalText="Delete Activity?"
      firstBtn="Delete"
      secondBtn="Cancel"
     />
    </>
   )}
   {isConfirmation && (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setIsConfirmation(false)}
      successText=" Your activity is succesfully deleted!"
      ClickOk={() => handleClick()}
     />
    </>
   )}
  </div>
 );
}
