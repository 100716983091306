/* eslint-disable no-undef */
import React from 'react';
import './Modal.css';
import '../../assets/css/style.css';
import cross from '../../assets/images/crossIcon.png';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';

const PreviewModal = (props) => {
 return (
  <div className="modalOverlay">
   <div className="detailPreviewModal previewModal userDetails">
    <span className="crossBtnContainer" onClick={props.clickCross}>
     <img src={cross} alt="" />
    </span>
    <div className="previewModalInner">
     {props.UserProfle}

     <table className="textContainer">
      <tr className="privewModalsTableRow">
       <td className="lightText">Username:</td>
       <td className="lightText darkText usrenameTd">{props.username}</td>
      </tr>
      <tr className="privewModalsTableRow">
       <td className="lightText">Mobile Number:</td>
       <td className="lightText darkText">{props.mobile}</td>
      </tr>
      {props.userDetails && (
       <>
        <tr className="privewModalsTableRow">
         <td className="lightText">language:</td>
         <td className="lightText darkText">{props.userDetails.language}</td>
        </tr>
        <tr className="privewModalsTableRow">
         <td className="lightText">registrationdate:</td>
         <td className="lightText darkText">
          {props.userDetails.created_at?.slice(0, 10)}
         </td>
        </tr>
        <tr className="privewModalsTableRow">
         <td className="lightText">category:</td>
         <td className="lightText darkText">
          {props.userDetails.category === '1'
           ? 'Pregnant Women'
           : props.userDetails.category === '2'
           ? 'Parent'
           : props.userDetails.category === '3'
           ? 'Health Worker'
           : null}
         </td>
        </tr>
        {props.userDetails.category === '2' && (
         <>
          <tr className="privewModalsTableRow">
           <td className="lightText">childname:</td>
           <td className="lightText darkText">
            {props.userDetails.child_name}
           </td>
          </tr>
          <tr className="privewModalsTableRow">
           <td className="lightText">gender:</td>
           <td className="lightText darkText">
            {props.userDetails.gender === '1'
             ? 'Boy'
             : props.userDetails.gender === '2'
             ? 'Girl'
             : null}
           </td>
          </tr>
          <tr className="privewModalsTableRow">
           <td className="lightText">dob:</td>
           <td className="lightText darkText">
            {props.userDetails.dob?.slice(0, 10)}
           </td>
          </tr>
          <tr className="privewModalsTableRow">
           <td className="lightText">relationship:</td>
           <td className="lightText darkText">
            {props.userDetails.relationship}
           </td>
          </tr>
         </>
        )}
        {props.userDetails.category === '1' && (
         <>
          <tr className="privewModalsTableRow">
           <td className="lightText">lmp:</td>
           <td className="lightText darkText">
            {props.userDetails.lmp?.slice(0, 10)}
           </td>
          </tr>
          <tr className="privewModalsTableRow">
           <td className="lightText">age:</td>
           <td className="lightText darkText">{props.userDetails.age}</td>
          </tr>
         </>
        )}
        {props.userDetails.category === '3' && (
         <>
          <tr className="privewModalsTableRow">
           <td className="lightText">User Type:</td>
           <td className="lightText darkText">
            {props.userDetails.employee_type === '1'
             ? 'ANM'
             : props.userDetails.employee_type === '2'
             ? 'ASHA'
             : props.userDetails.employee_type === '3'
             ? 'Doctor'
             : props.userDetails.employee_type === '4'
             ? 'Others'
             : null}
           </td>
          </tr>
          {props.userDetails.employee_type === '4' ? (
           <tr className="privewModalsTableRow">
            <td className="lightText">other_type:</td>
            <td className="lightText darkText">
             {props.userDetails.other_type}
            </td>
           </tr>
          ) : null}
         </>
        )}
       </>
      )}
     </table>
    </div>
   </div>
  </div>
 );
};
export default PreviewModal;
