export const BASE_URL = 'https://akfpaalan.com/';
//export const BASE_URL = 'http://localhost:3000/';

export const POST_REQUEST = 'POST';
export const DELETE_REQUEST = 'DELETE';
export const PATCH_REQUEST = 'PATCH';
export const CONTENT_TYPE = 'application/json';
export const PUBLIC_TOKEN = 'Logicease123';
export const GET_REQUEST = 'GET';
export const CATEGORYPREGANENTWOMEN = '1';
export const CATEGORYPARENTS = '2';
export const CATEGORYHEALTHCAREWORKER = '3';
export const STARTINDEX = 1;
export const ENDINDEX = 25;
export const ENGLISH = 'English';
export const HINDI = 'Hindi';
