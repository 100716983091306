import { useEffect, useState } from 'react';
import Avatar from '../assets/images/profileimg.png';
import Image from '../libs/ImageHelper';
import Star from '../assets/images/star.svg';
import GreyStar from '../assets/images/greyStar.svg';
import { postRequest } from '../services/ApiService';
import { ENDINDEX, STARTINDEX } from '../libs/Constants';
import DeleteModal from '../components/Modal/DeleteModal';
import SearchInput from '../components/inputFields/SearchInput.jsx';
import Loader from 'react-js-loader';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

export default function FeedbackData() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [feedbackData, setFeedbackData] = useState([]);
 const [feedback, setFeedback] = useState({
  totalFeedback: 0,
  averageFeedback: 0,
  oneStarFeedback: 0,
  twoStarFeedback: 0,
  threeStarFeedback: 0,
  fourStarFeedback: 0,
  fiveStarFeedback: 0,
 });
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [loader2, setLoader2] = useState(false);
 const [loading, setLoading] = useState(true);
 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [orderBy, setOrderBy] = useState('desc');
 const [LoadMore, setLoadMore] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);

 const getFeedbackData = async () => {
  setLoading(true);
  const response = await postRequest('admin/feedback/get', {
   StartIndex: STARTINDEX,
   EndIndex: ENDINDEX,
   OrderBy: orderBy,
  });
  if (response) {
   console.log(JSON.stringify(response.result[1][0]));
   setFeedbackData(response.result[0]);
   setFeedback({
    totalFeedback: response.result[1][0].total_userfeedback,
    averageFeedback: response.result[2][0].average_userfeedback,
    oneStarFeedback: response.result[3][0].total_1staruser,
    twoStarFeedback: response.result[4][0].total_2staruser,
    threeStarFeedback: response.result[5][0].total_3staruser,
    fourStarFeedback: response.result[6][0].total_4staruser,
    fiveStarFeedback: response.result[7][0].total_5staruser,
   });

   setLoading(false);
   setHideLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };

 const getSearchItem = async () => {
  setLoading(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement.length > 0) {
   const response = await postRequest('admin/feedback/search', {
    SearchText: inputElement,
    StartIndex: STARTINDEX,
    EndIndex: ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setHideLoadMore(false);
    setLoadMore(true);
    setFeedbackData(response.result[0]);
    setLoading(false);
   } else {
    setHideLoadMore(true);
    setFeedbackData([]);
    setLoading(false);
   }
  } else {
   setCreateValidItemModal(true);
   setLoading(false);
  }
 };

 const getMoreSearchItems = async () => {
  setLoader2(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement) {
   const response = await postRequest('admin/feedback/search', {
    SearchText: inputElement,
    StartIndex: lastIndex + STARTINDEX,
    EndIndex: lastIndex + ENDINDEX,
   });
   if (response && response.result[0].length > 0) {
    setLastIndex(lastIndex + ENDINDEX);
    setFeedbackData([...feedbackData, ...response.result[0]]);
    setLoader2(false);
   } else {
    setHideLoadMore(true);
    setLoader2(false);
   }
  } else {
   setLoader2(false);
  }
 };

 const getFeedbackDataOnPagination = async () => {
  setLoader2(true);
  const response = await postRequest('admin/feedback/get', {
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result[0].length > 0) {
   console.log(response.result[0]);
   setLastIndex(lastIndex + ENDINDEX);
   setFeedbackData([...feedbackData, ...response.result[0]]);
   setLoader2(false);
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };

 useEffect(() => {
  getFeedbackData();
  // deleteData();
 }, [orderBy]); // Add orderBy as a dependency to refetch data when it changes

 return (
  <div>
   <div className="topBarContainer recordsOuter">
    <div className="container">
     <div className="row">
      <div className="top_Bar row">
       <SearchInput OnClick={() => getSearchItem()} />
       <div
        className="oldest_button"
        onClick={() => {
         const newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
         setOrderBy(newOrderBy);
         // Fetch data again with the new order
         getFeedbackData();
        }}
       >
        Click here for {orderBy === 'desc' ? 'oldest' : 'newest'}
       </div>
      </div>

      <div className="d-flex">
       <p className="star_records">
        1 Star Records: {feedback.oneStarFeedback}
       </p>
       <br />
       <p className="star_records">
        2 Star Records: {feedback.twoStarFeedback}
       </p>
       <br />
       <p className="star_records">
        3 Star Records: {feedback.threeStarFeedback}
       </p>
       <br />
       <p className="star_records">
        4 Star Records: {feedback.fourStarFeedback}
       </p>
       <br />
       <p className="star_records">
        5 Star Records: {feedback.fiveStarFeedback}
       </p>
       <p className="star_records">
        Average Rating: {feedback.averageFeedback.toFixed(1)}/5
       </p>
      </div>
      <p className="totalFeedback">
       1 - {lastIndex} of {feedback.totalFeedback}
      </p>
     </div>
    </div>
   </div>
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <Loader type="bubble-top" bgColor={'#005190'} size={52} />
     </div>
    </div>
   ) : feedbackData.length > 0 ? (
    <>
     {feedbackData.map((item, i) => {
      return (
       <div className="container" key={i}>
        <div className="testimonialContainer">
         <div className="testimonialTopBarContainer">
          <div className="testimonialContentMainContainer">
           {item.profile_image ? (
            <Image src={item.profile_image} />
           ) : (
            <Image src={Avatar} />
           )}
           <div className="testimonialContentContainer">
            <p>{item.username}</p>
            <span>
             Mobile <span className="userId">{item.mobile_no}</span>
            </span>
           </div>
          </div>

          <div className="ratingIconContainer">
           {Array(item.user_ratings)
            .fill(0)
            .map((_, index) => (
             <Image src={Star} className="star" key={index} />
            ))}
           {Array(5 - item.user_ratings)
            .fill(0)
            .map((_, index) => (
             <Image src={GreyStar} className="star" key={index} />
            ))}
          </div>
         </div>
         <p className="subtitle">{item.user_feedback}</p>
        </div>
       </div>
      );
     })}
    </>
   ) : loading ? null : (
    <div className="no-data">NO DATA FOUND</div>
   )}
   {createValidItemModal ? (
    <DeleteModal
     isSuccessModal={true}
     Onclick={() => setCreateValidItemModal(false)}
     successText="Please enter some text."
     ClickOk={() => setCreateValidItemModal(false)}
    />
   ) : null}
   <>
    {hideLoadMore || loading ? null : (
     <div className="col-12 col-lg-12 text-center">
      {loader2 ? (
       <div id="preloaderloadAreaSpecificLoader">
        <div className="preload-contentAreaSpecificLoader">
         <Loader type="bubble-top" bgColor={'#005190'} size={38} />
        </div>
       </div>
      ) : null}
      {LoadMore ? (
       <Button buttonTxt="Show More" OnClick={() => getMoreSearchItems()} />
      ) : (
       <Button buttonTxt="Show More" OnClick={() => getFeedbackDataOnPagination()} />
      )}
     </div>
    )}
   </>
  </div>
 );
}

