import React from 'react';
import '../../assets/css/style.css';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';

const SelectField = (props) => {
 return (
  <div
   className="generatetextbody generateTextBody "
   style={{ marginTop: '14px' }}
  >
   <label className="form-label">{props.Lable}</label>
   <select
    className="form-select form-control Select-body"
    aria-label="Default select example"
    onChange={props.OnChange}
   >
    <option value="1" className="form-control">
     {props.option1}
    </option>
    <option value="2" className="form-control">
     {props.option2}
    </option>
    <option value="3" className="form-control">
     {props.option3}
    </option>
   </select>
  </div>
 );
};
export default SelectField;
