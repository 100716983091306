import { useEffect, useState } from 'react';
import '../assets/css/pagination.css';
import { getRequest, postRequest } from '../services/ApiService';
import DeleteModal from '../components/Modal/DeleteModal';
import { useHistory } from 'react-router-dom';
import ActivityDetailFields from '../components/activityDetailFields/ActivityDetailFields.jsx';

export default function UpdateActivity(props) {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [category, setCategory] = useState('');
 const [tag, setTag] = useState([]);
 const [tagId, setTagId] = useState('');
 const [pregnancyMonths, setPregnancyMonths] = useState([]);
 const [pregnancyMonthsId, setPregnancyMonthsId] = useState('');
 const [ageGroup, setAgeGroup] = useState([]);
 const [ageGroupId, setAgeGroupId] = useState('');
 const [englishName, setEnglishName] = useState('');
 const [hindiName, setHindiName] = useState('');
 const [activityImageEnglish, setActivityImageEnglish] = useState('');
 const [activityImageHindi, setActivityImageHindi] = useState('');
 const [englishTip, setEnglishTip] = useState('');
 const [hindiTip, setHindiTip] = useState('');
 const [englishBrainy, setEnglishBrainy] = useState('');
 const [hindiBrainy, setHindiBrainy] = useState('');
 const [englishImageTip, setEnglishImageTip] = useState('');
 const [hindiImageTip, setHindiImageTip] = useState('');
 const [englishSoundTip, setEnglishSoundTip] = useState('');
 const [hindiSoundTip, setHindiSoundTip] = useState('');
 const [englishSoundBrainy, setEnglishSoundBrainy] = useState('');
 const [hindiSoundBrainy, setHindiSoundBrainy] = useState('');
 const [activityId, setActivityId] = useState('');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [showAlertModal, setShowAlertModal] = useState(false);
 const [showErrorAlertModal, setshowErrorAlertModal] = useState(false);
 const [loading, setLoading] = useState(false);

 function handleOnclick() {
  history.push('./activityContent');
 }
 const onCategoryChange = (categoryId) => {
  onDropDownTag(categoryId);
  setCategory(categoryId);
 };

 const onDropDownTag = async (id) => {
  const response = await postRequest('/activities/get/tags', {
   Category: id,
  });
  if (response) {
   console.log(response);
   response.result.shift();
   setTag(response.result);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 const onDropDownPregnancyMonths = async () => {
  const response = await getRequest('/admin/users/pregnancymonths/get');
  if (response) {
   console.log(response);
   setPregnancyMonths(response.result);
   setPregnancyMonthsId(response.result[0].pregnancy_id);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 const onDropDownAgeGroup = async () => {
  const response = await getRequest('/admin/users/agegroup/get');
  if (response) {
   console.log(response);
   setAgeGroup(response.result);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 const onLoadFillDetails = async (activityDetails) => {
  console.log(activityDetails);
  setCategory(activityDetails.category);
  onDropDownTag(activityDetails.category);
  setTagId(activityDetails.tag);
  setPregnancyMonthsId(activityDetails.agegroupId);
  setAgeGroupId(activityDetails.agegroupId);
  setEnglishName(activityDetails.title_e);
  setHindiName(activityDetails.title_h);
  setActivityImageEnglish(activityDetails.image_e);
  setActivityImageHindi(activityDetails.image_h);
  setEnglishTip(activityDetails.task_e);
  setHindiTip(activityDetails.task_h);
  setEnglishBrainy(activityDetails.explanation_e);
  setHindiBrainy(activityDetails.explanation_h);
  setEnglishImageTip(activityDetails.imageDetail_e);
  setHindiImageTip(activityDetails.imageDetail_h);
  setEnglishSoundTip(activityDetails.soundTask_e);
  setHindiSoundTip(activityDetails.soundTask_h);
  setEnglishSoundBrainy(activityDetails.soundExplanation_e);
  setHindiSoundBrainy(activityDetails.soundExplanation_h);
  setActivityId(activityDetails.id);
 };

 useEffect(() => {
  onDropDownPregnancyMonths();
  onDropDownAgeGroup();
  onLoadFillDetails(props.location.details);
 }, []);

 const onSubmit = async () => {
  setLoading(true);
  if (
   category &&
   tagId &&
   englishName &&
   hindiName &&
   activityImageEnglish &&
   activityImageHindi &&
   englishTip &&
   englishBrainy &&
   englishImageTip &&
   englishSoundTip &&
   englishSoundBrainy &&
   hindiTip &&
   hindiBrainy &&
   hindiImageTip &&
   hindiSoundTip &&
   hindiSoundBrainy
  ) {
   const response = await postRequest('/admin/activities/update', {
    Category: category,
    TagId: tagId,
    Title_En: englishName,
    Title_H: hindiName,
    ActivityImage_En: activityImageEnglish,
    ActivityImage_H: activityImageHindi,
    AgeGroupId: category === '1' ? pregnancyMonthsId : ageGroupId,
    Task_En: englishTip,
    Explanation_En: englishBrainy,
    Image_En: englishImageTip,
    Sound_Task_En: englishSoundTip,
    Sound_Explanation_En: englishSoundBrainy,
    Task_H: hindiTip,
    Explaination_H: hindiBrainy,
    Image_H: hindiImageTip,
    Sound_Task_H: hindiSoundTip,
    Sound_Explanation_H: hindiSoundBrainy,
    ActivityId: activityId,
   });
   if (response) {
    console.log('response', response);
    setLoading(false);
    setShowSuccessModal(true);
   }
  } else setShowAlertModal(true);
  setLoading(false);
 };

 return (
  <>
   <ActivityDetailFields
    EventHandler1={onCategoryChange}
    EventHandler2={setTagId}
    EventHandler3={category === '1' ? setPregnancyMonthsId : setAgeGroupId}
    EventHandler5={setEnglishName}
    EventHandler6={setHindiName}
    EventHandler7={setActivityImageEnglish}
    EventHandler8={setActivityImageHindi}
    EventHandler9={setEnglishTip}
    EventHandler10={setHindiTip}
    EventHandler11={setEnglishBrainy}
    EventHandler12={setHindiBrainy}
    EventHandler13={setEnglishImageTip}
    EventHandler14={setHindiImageTip}
    EventHandler15={setEnglishSoundTip}
    EventHandler16={setHindiSoundTip}
    EventHandler17={setEnglishSoundBrainy}
    EventHandler18={setHindiSoundBrainy}
    Value1={category}
    Value2={tagId}
    Value3={category === '1' ? pregnancyMonthsId : ageGroupId}
    Value5={englishName}
    Value6={hindiName}
    Value7={activityImageEnglish}
    Value8={activityImageHindi}
    Value9={englishTip}
    Value10={hindiTip}
    Value11={englishBrainy}
    Value12={hindiBrainy}
    Value13={englishImageTip}
    Value14={hindiImageTip}
    Value15={englishSoundTip}
    Value16={hindiSoundTip}
    Value17={englishSoundBrainy}
    Value18={hindiSoundBrainy}
    Dropdown1={tag.map((x, y) => (
     <option key={x.tag_id} value={x.tag_id}>
      {x.tag_en}
     </option>
    ))}
    Dropdown2={
     category === '1'
      ? pregnancyMonths.map((x, y) => (
         <option key={x.pregnancy_id} value={x.pregnancy_id}>
          {x.pregnancy_months}
         </option>
        ))
      : ageGroup.map((x, y) => (
         <option key={x.agegroup_id} value={x.agegroup_id}>
          {x.range}
         </option>
        ))
    }
    label={category === '1' ? 'Pregnancy Months' : 'Age Group'}
    OnSubmitclick={() => onSubmit()}
    loading={loading}
   />
   {showAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowAlertModal(false)}
      successText="Please enter all input fields."
      ClickOk={() => setShowAlertModal(false)}
     />
    </>
   ) : null}
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Activity updated successfully"
      ClickOk={() => handleOnclick()}
     />
    </>
   ) : null}
   {showErrorAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setshowErrorAlertModal(false)}
      successText="Some error Occured"
      ClickOk={() => setshowErrorAlertModal(false)}
     />
    </>
   ) : null}
  </>
 );
}
