import React, { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import BackArrow from '../assets/images/backbutton.png';
import { ENDINDEX, STARTINDEX } from '../libs/Constants';
import { patchRequest, postRequest } from '../services/ApiService';
import moment from 'moment';
import Loader from 'react-js-loader';
import DeleteModal from '../components/Modal/DeleteModal';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

export default function ViewForum(props) {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const details = props.location.details;
 const [isComment, setIsComment] = useState(false);
 const [loading, setLoading] = useState(false);
 const [rownumber, setRowNumber] = useState(0);
 const [forumData, setForumData] = useState([]);

 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const [loader2, setLoader2] = useState(false);
 const [isModal, setIsModal] = useState(false);
 const [forumStatus, setForumStatus] = useState({
  answer: 'Pending',
  answerId: 0,
  reply: 'Pending',
  replyId: 0,
  approve: 'None',
 });
 console.log("fndjb",forumData,forumStatus)

 const getForumData = async () => {
  setLoading(true);
  const response = await postRequest('admin/forum/question/details', {
   QuestionId: details.question_id,
   StartIndex: STARTINDEX,
   EndIndex: ENDINDEX,
  });
  if (response) {
   if (response.result[0].length === 0) {
    setHideLoadMore(true);
   } else {
    setHideLoadMore(false);
   }
   console.log(JSON.stringify(response.result[0]));
   setForumData(response.result[0]);
   setLoading(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };

 const getForumDataOnPagination = async () => {
  setLoader2(true);
  const response = await postRequest('admin/forum/question/details', {
   QuestionId: details.question_id,
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result[0].length > 0) {
   setLastIndex(lastIndex + ENDINDEX);
   setForumData([...forumData, ...response.result[0]]);
   setLoader2(false);
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };
 const forumAnswerReplyStatus = async () => {
  if (forumStatus.approve === 'Approve All?') {
   const response = await patchRequest('admin/forum/approve/all', {
    QuestionId: details.question_id,
   });
   if (response) {
    console.log(response);
    if (response.msg === 'success') {
     setIsModal(false);
     getForumData();
    } else {
    }
   } else {
   }
  } else {
   if (forumStatus.answer) {
    const response = await patchRequest('admin/forum/answer/status', {
     AnswerStatus:
      forumStatus.answer.toLowerCase() === 'approve?' ? 'Approved' : 'Rejected',
     AnswerId: forumStatus.answerId,
    });
    if (response) {
     console.log(response);
     if (response.msg === 'success') {
      setIsModal(false);
      getForumData();
     } else {
      //setAlertModal(true);
     }
    } else {
    }
   } else {
    const response = await patchRequest('admin/forum/reply/status', {
     ReplyStatus:
      forumStatus.reply.toLowerCase() === 'approve?' ? 'Approved' : 'Rejected',
     ReplyId: forumStatus.replyId,
    });
    if (response) {
     console.log(response);
     if (response.msg === 'success') {
      setIsModal(false);
      getForumData();
     } else {
      //setAlertModal(true);
     }
    } else {
    }
   }
  }
 };

 useEffect(() => {
  getForumData();
 }, []);
 return (
  <div>
   <div className="topBarContainer">
    <div className="container">
     <div className="row viewForumTop">
      <div className="col-md-6 searchContainer">
       <div className="backButton">
        <a href="./forumData">
         <img src={BackArrow} alt="Back Arrow" className="backArrow" />
        </a>
       </div>
      </div>
      <Button
       topBarButton={true}
       smallButton={true}
       LableTxt="Approve All"
       buttonOnClick={() => {
        setIsModal(true);
        setForumStatus({ approve: 'Approve All?' });
       }}
      />
     </div>
    </div>
   </div>

   <div style={{ paddingTop: '20px' }}>
    <div className="questionRow">
     <h4 className="questionText">Q{details.rownumber} :</h4>
     {details.type && details.type.toLowerCase() === 'audio' ? (
      <ReactAudioPlayer
       src={details.question}
       controls
       controlsList="nodownload"
      />
     ) : (
      <h4 className="questionText">{details.question}</h4>
     )}
    </div>
    <span className="responsesText">
     {details.total_replies > 1
      ? details.total_replies + ' responses'
      : details.total_replies + ' response'}
    </span>
   </div>
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <Loader type="bubble-top" bgColor={'#005190'} size={52} />
     </div>
    </div>
   ) : forumData.length > 0 ? (
    <>
     {forumData.map((item, i) => {
      return (
       <div>
        <div className="card newForumCard">
         <div className="row">
          <div className="col-12 questionContainer commentContainer">
           <div className="commentContainer2">
            <div className="commentAnswerContainer2 feedbackStatus">
             <span className="userId">{item.answerusername}</span>
             <span className="date">
              {moment(item.answerdate).format('DD/MM/YYYY')}
             </span>

             {item.answertype.toLowerCase() === 'audio' ? (
              <ReactAudioPlayer
               //  style={{ width: '40%' }}
               src={item.answer}
               controls="true"
               className="audioPlayer"
              />
             ) : (
              item.answer
             )}
            </div>
            <div className="col-md-3 questionContainer categoryContainer">
             <h5 className="category"> Answer Status</h5>
             <span
              style={{
               color:
                item.answerstatus === 'Approved'
                 ? 'green'
                 : item.answerstatus === 'Rejected'
                 ? 'red'
                 : '#005190',
              }}
             >
              {item.answerstatus}
             </span>
            </div>
            <div className="buttonContainer">
             {item.answerstatus === 'Pending' ? (
              <>
               <button
                className="viewAll"
                onClick={() => {
                 setForumStatus({
                  answer: 'Reject?',
                  answerId: item.answer_id,
                 });
                 setIsModal(true);
                }}
               >
                Decline
               </button>

               <Button
                buttonTxt="Approve"
                OnClick={() => {
                 setIsModal(true);
                 setForumStatus({
                  answer: 'Approve?',
                  answerId: item.answer_id,
                 });
                }}
               />
              </>
             ) : item.answerstatus === 'Approved' ? (
              <button
               className="viewAll"
               onClick={() => {
                setIsModal(true);
                setForumStatus({
                 answer: 'Reject?',
                 answerId: item.answer_id,
                });
               }}
              >
               Decline
              </button>
             ) : (
              <>
               <Button
                buttonTxt="Approve"
                OnClick={() => {
                 setIsModal(true);
                 setForumStatus({
                  answer: 'Approve?',
                  answerId: item.answer_id,
                 });
                }}
               />
              </>
             )}
            </div>
           </div>
           {item.reply ? (
            <button
             className="viewAll commentButton"
             onClick={() => {
              setIsComment(!isComment);
              setRowNumber(item.rownumber);
             }}
            >
             Comment
            </button>
           ) : null}
           {item.rownumber === rownumber ? (
            // isComment &&
            <>
             {' '}
             <span className="date"></span>
             <div className="commentContainer2 CommentsSection">
              <div className="commentAnswerContainer feedbackStatus2">
               <span className="userId">{item.replyusername}</span>
               <span className="date">
                {moment(item.replydate).format('DD/MM/YYYY')}
               </span>
               <span className="date">
                {item.replytype === 'audio' ? (
                 <ReactAudioPlayer
                  //  style={{ width: '40%' }}
                  src={item.answer}
                  controls="true"
                  className="audioPlayer"
                 />
                ) : (
                 item.reply
                )}
               </span>
              </div>
              <div className="col-md-3 questionContainer categoryContainer">
               <h5 className="category"> Comment Status</h5>
               <span
                style={{
                 color:
                  item.replystatus === 'Approved'
                   ? 'green'
                   : item.replystatus === 'Rejected'
                   ? 'red'
                   : '#005190',
                }}
               >
                {item.replystatus}
               </span>
              </div>
              <div className=" questionContainer">
               <div className="buttonContainer">
                {item.replystatus === 'Pending' ? (
                 <>
                  <button
                   href=""
                   className="viewAll"
                   onClick={() => {
                    setForumStatus({
                     reply: 'Reject?',
                     replyId: item.reply_id,
                    });
                    setIsModal(true);
                   }}
                  >
                   Decline
                  </button>

                  <Button
                   buttonTxt="Approve"
                   OnClick={() => {
                    setIsModal(true);
                    setForumStatus({
                     reply: 'Approve?',
                     replyId: item.reply_id,
                    });
                   }}
                  />
                 </>
                ) : item.replystatus === 'Approved' ? (
                 <button
                  className="viewAll"
                  onClick={() => {
                   setIsModal(true);
                   setForumStatus({ reply: 'Reject?', replyId: item.reply_id });
                  }}
                 >
                  Decline
                 </button>
                ) : (
                 <>
                  <Button
                   buttonTxt="Approve"
                   OnClick={() => {
                    setIsModal(true);
                    setForumStatus({
                     reply: 'Approve?',
                     replyId: item.reply_id,
                    });
                   }}
                  />
                 </>
                )}
               </div>
              </div>
             </div>
            </>
           ) : null}
          </div>
         </div>
        </div>
       </div>
      );
     })}
    </>
   ) : loading ? null : (
    <div className="no-data">NO DATA FOUND</div>
   )}

   <>
    {hideLoadMore || loading ? null : (
     <div className="col-12 col-lg-12 text-center">
      {loader2 ? (
       <div id="preloaderloadAreaSpecificLoader">
        <div className="preload-contentAreaSpecificLoader">
         <Loader type="bubble-top" bgColor={'#005190'} size={38} />
        </div>
       </div>
      ) : null}
      <>
       <Button
        buttonTxt="Show More"
        OnClick={() => getForumDataOnPagination()}
       />
      </>
     </div>
    )}
   </>

   {isModal && (
    <>
     <DeleteModal
      onClick={() => setIsModal(false)}
      DeleteOnclick={() => forumAnswerReplyStatus()}
      CancelOnclick={() => setIsModal(false)}
      text={true}
      modalText={
       forumStatus.approve
        ? forumStatus.approve
        : forumStatus.answer
        ? forumStatus.answer
        : forumStatus.reply
      }
      firstBtn="Yes"
      secondBtn="No"
     />
    </>
   )}
  </div>
 );
}
