import React, { useState } from 'react';
import SpeakerIcon from '../../assets/images/speaker.svg';
import ReactAudioPlayer from 'react-audio-player';
import { CATEGORYPREGANENTWOMEN, ENGLISH } from '../../libs/Constants';
import { postRequest } from '../../services/ApiService';
import DeleteModal from '../Modal/DeleteModal';
import Loader from 'react-js-loader';
import '../../assets/css/style.css';
export default function ActivityDetailCard(props) {
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [loading, setLoading] = useState(false);
 const sendNotification = async () => {
  setLoading(true);
  console.log(props);
  const response = await postRequest('admin/notifications/add', {
   Category: props.category,
   Type: 'Activity',
   Image: props.image_e,
   Content_En: props.title_e,
   Content_H: props.title_h,
   Activity_Id: props.id,
   ResourceType: '',
   Desciption: '',
  });
  if (response) {
   console.log(JSON.stringify(response));
   const responseNotification = await postRequest(
    'admin/notifications/send/topic',
    {
     title: props.title_e,
     body: props.title_h,
     topicname:
      props.category === CATEGORYPREGANENTWOMEN ? 'Pregnant_Women' : 'Parent',
    }
   );
   if (responseNotification) {
    setLoading(false);
    setShowSuccessModal(true);
   } else {
    alert('Some error occurred');
   }
  } else {
  }
 };
 return (
  <>
   <div className="card activityCard p-3">
    <div className="row">
     <div className="col-md-4 align-items-center d-flex">
      <img src={props.image} alt="" style={{ width: '240px' }} />
     </div>
     <div className="col-md-8 mt-2 notificationMainContainer">
      <div className="audioPlayerContainer">
       <h6 className="category activity">
        Activity
        <img src={SpeakerIcon} alt="" />
       </h6>
       {props.language === ENGLISH ? null : (
        <ReactAudioPlayer
         style={{ width: '40%' }}
         src={props.soundTask}
         controls
        />
       )}
      </div>
      <p className="sub mb-3">{props.task}</p>
      <div className="audioPlayerContainer">
       <h6 className="category activity">Brainy</h6>
       {props.language === ENGLISH ? null : (
        <ReactAudioPlayer
         style={{ width: '40%' }}
         src={props.soundExplanation}
         controls="true"
        />
       )}
      </div>
      <p className="sub mb-3">{props.explanation}</p>
      <div>
       <button
        onClick={() => {
         sendNotification();
        }}
        className="downloadBtn updateBtn sendBtn"
       >
        {loading ? (
         <div className="sendButtonLoader">
          <div className="preload-contentAreaSpecificLoader">
           <Loader type="bubble-top" bgColor={'#005190'} size={30} />
          </div>
         </div>
        ) : null}
        Send Notification
       </button>
      </div>
     </div>
    </div>
   </div>
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Notification send successfully"
      ClickOk={() => setShowSuccessModal(false)}
     />
    </>
   ) : null}
  </>
 );
}
