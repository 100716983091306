import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../assets/css/login.css';
import '../assets/css/responsive.css';
import logo from '../assets/images/applogo.png';
import Button from '../components/buttons/Button';

import { postRequest } from '../services/ApiService';
const Login = () => {
 const history = useHistory();
 const [name, setName] = useState('');
 const [password, setPassword] = useState('');
 const onLogin = async () => {
  if (!(name && password)) alert('Please enter all fields');
  const response = await postRequest('admin/users/login', {
   Name: name,
   Password: password,
  });
  if (response) {
   if (response?.rows[0]) {
    sessionStorage.setItem('userId', response.rows[0].id);
    history.push('/customers');
   } else {
    alert('Wrong Email or Password');
   }
  } else {
   alert('Wrong Email or Password');
  }
 };
 return (
  <div>
   <div className="Container">
    <div className="overlay">
     <div className="modal loginModal">
      <div className="logoOuter">
       <img className="logo__image" src={logo} alt="logo" />
      </div>
      <div>
       <p className="login-text">Login</p>
      </div>
      <div className="row form_inputs">
       {' '}
       <input
        type="text"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
        onChange={(event) => setName(event.target.value)}
       />
      </div>
      <div className="row  form_inputs">
       {' '}
       <input
        type="password"
        id="exampleInputEmail2"
        placeholder="Password"
        onChange={(event) => setPassword(event.target.value)}
       />
      </div>
      <Button OnClick={() => onLogin()} buttonTxt="LOGIN" />
     </div>
    </div>
   </div>
  </div>
 );
};
export default Login;
