import { useState } from 'react';
import { CATEGORYPARENTS, CATEGORYPREGANENTWOMEN } from '../libs/Constants';
import { postRequest } from '../services/ApiService';
import DeleteModal from '../components/Modal/DeleteModal';
import TextInput from '../components/inputFields/TextInput.jsx';
import SelectField from '../components/dropdown/SelectField';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

const Generatemessage = (params) => {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [category, setCategory] = useState('1');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [showAlertModal, setShowAlertModal] = useState(false);
 const [showErrorAlertModal, setshowErrorAlertModal] = useState(false);
 const [loading, setLoading] = useState(false);

 const sendGroupNotification = async () => {
  setLoading(true);
  const inputTitle = document.getElementById('titleName').value;
  const inputDescription = document.getElementById('description').value;
  if (inputTitle && inputDescription) {
   const response = await postRequest('admin/notifications/add', {
    Category: category,
    Type: 'Others',
    Image: '',
    Content_En: inputTitle,
    Content_H: inputTitle,
    Activity_Id: '',
    ResourceType: '',
    Description: inputDescription,
   });
   if (response) {
    console.log(JSON.stringify(response));
    const responseNotification = await postRequest(
     'admin/notifications/send/topic',
     {
      title: inputTitle,
      body: inputDescription,
      topicname:
       category === CATEGORYPREGANENTWOMEN
        ? 'Pregnant_Women'
        : category === CATEGORYPARENTS
        ? 'Parent'
        : 'All',
     }
    );
    if (responseNotification) {
     setLoading(false);
     setShowSuccessModal(true);
     document.getElementById('titleName').value = '';
     document.getElementById('description').value = '';
    } else {
     setshowErrorAlertModal(true);
    }
   }
  } else {
   setLoading(false);
   setShowAlertModal(true);
  }
 };

 return (
  <div>
   <div
    style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}
   >
    <div className="card previewcard createEvent-card">
     <div className="generatetexttitle">
      <h2>GENERATE YOUR MESSAGE</h2>
     </div>
     <TextInput Id="titleName" Placeholder="Title" />
     <TextInput Id="description" Placeholder="Description" />
     <SelectField
      Lable="Category"
      OnChange={(event) => setCategory(event.target.value)}
      option1="Pregnant Women"
      option2="Parents"
      option3="ALL"
     />
     <>
      <Button
       buttonTxt="Send"
       OnClick={() => sendGroupNotification()}
       loading={loading}
      />
     </>
    </div>
   </div>
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Notification send successfully"
      ClickOk={() => setShowSuccessModal(false)}
     />
    </>
   ) : null}
   {showAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowAlertModal(false)}
      successText="Please enter all input fields."
      ClickOk={() => setShowAlertModal(false)}
     />
    </>
   ) : null}
   {showErrorAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setshowErrorAlertModal(false)}
      successText="Some error Occured"
      ClickOk={() => setshowErrorAlertModal(false)}
     />
    </>
   ) : null}
  </div>
 );
};

export default Generatemessage;
