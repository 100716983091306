import React from 'react';
import './Modal.css';
import cross from '../../assets/images/crossIcon.png';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';

const DeleteModal = (props) => {
 console.log('modal', props.modalText);
 return (
  <>
   {!props.isSuccessModal ? (
    <div className="modalOverlay">
     <div className="requestModal">
      <span className="crossBtnContainer" onClick={props.onClick}>
       <img src={cross} alt="" />
      </span>
      <div className="del">
       {!props.text ? (
        <h4>{props.modalText}</h4>
       ) : (
        <h4>Do you really want to {props.modalText} </h4>
       )}
      </div>
      <div className="delete_btn">
       <button type="submit" onClick={props.DeleteOnclick}>
        {props.firstBtn}
       </button>
       <button type="submit" onClick={props.CancelOnclick}>
        {props.secondBtn}
       </button>
      </div>
     </div>
    </div>
   ) : (
    <div className="modalOverlay">
     <div className="requestModal">
      <span className="crossBtnContainer" onClick={props.Onclick}>
       <img src={cross} alt="" />
      </span>
      <div className="del">
       <h4>{props.successText}</h4>
      </div>
      <div className="delete_btn">
       <button type="submit" onClick={props.ClickOk}>
        Ok
       </button>
      </div>
     </div>
    </div>
   )}
  </>
 );
};
export default DeleteModal;
