import React from 'react';
import '../../assets/css/style.css';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';

const TextInput = (props) => {
 return (
  <>
   {props.dateInputField ? (
    <div className="col date_picker">
     <input
      type={props.DatePicker ? 'date' : 'text'}
      className={props.customers ? 'date-pick form-control ' : 'form-control '}
      value={props.Value}
      onChange={props.OnChange}
     />
    </div>
   ) : (
    <div className="generatetextbody">
     <input
      type={props.DatePicker ? 'date' : 'text'}
      className="form-control"
      id={props.Id}
      placeholder={props.Placeholder}
      maxLength={props.MaxLength}
      value={props.Value}
      onChange={props.OnChange}
     />
    </div>
   )}
  </>
 );
};
export default TextInput;
