import { useEffect, useState } from 'react';
import Avatar from '../assets/images/profileimg.png';
import Image from '../libs/ImageHelper';
import { Link } from 'react-router-dom';
import { ENDINDEX, STARTINDEX } from '../libs/Constants';
import { patchRequest, postRequest } from '../services/ApiService';
import Loader from 'react-js-loader';
import ReactAudioPlayer from 'react-audio-player';
import DeleteModal from '../components/Modal/DeleteModal';
import PreviewModal from '../components/Modal/PreviewModal';
import SearchInput from '../components/inputFields/SearchInput';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export default function ForumData() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [loading, setLoading] = useState(false);
 const [showPreviewModal, setShowPreviewModal] = useState(false);
 const [userDetail, setUserDetail] = useState();
 const [forumData, setForumData] = useState([]);
 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [LoadMore, setLoadMore] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const [loader2, setLoader2] = useState(false);
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [isModal, setIsModal] = useState(false);
 const [question, setQuestion] = useState({
  questionId: 0,
  questionStatus: 'Pending',
 });
 const [customerDetails, setCustomerDetails] = useState({
  image: '',
  mobile: '',
  username: '',
 });

 const getForumData = async () => {
  setLoading(true);
  const response = await postRequest('admin/forum/questions/get', {
   StartIndex: STARTINDEX,
   EndIndex: ENDINDEX,
  });
  if (response) {
   console.log("response________",JSON.stringify(response.result[0]));
   setForumData(response.result[0]);
   setLoading(false);
   setHideLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };
 const getForumDataOnPagination = async () => {
  setLoader2(true);
  const response = await postRequest('admin/forum/questions/get', {
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result[0].length > 0) {
   setLastIndex(lastIndex + ENDINDEX);
   setForumData([...forumData, ...response.result[0]]);
   setLoader2(false);
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };

 const getSearchItem = async () => {
  setLoading(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement.length > 0) {
   const response = await postRequest('admin/forum/question/search', {
    SearchText: inputElement,
    StartIndex: STARTINDEX,
    EndIndex: ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setHideLoadMore(false);
    setLoadMore(true);
    setForumData(response.result[0]);
    setLoading(false);
   } else {
    setHideLoadMore(true);
    setForumData([]);
    setLoading(false);
   }
  } else {
   setCreateValidItemModal(true);
   setLoading(false);
  }
 };
 const getMoreSearchItems = async () => {
  setLoader2(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement) {
   const response = await postRequest('admin/forum/question/search', {
    SearchText: inputElement,
    StartIndex: lastIndex + STARTINDEX,
    EndIndex: lastIndex + ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setLastIndex(lastIndex + ENDINDEX);
    setForumData([...forumData, ...response.result[0]]);
    setLoader2(false);
   } else {
    setHideLoadMore(true);
    setLoader2(false);
   }
  } else {
   setLoader2(false);
  }
 };

 const previewCustomerDetails = async (item) => {
  const response = await postRequest('admin/users/details/get', {
   UserId: item.user_id,
  });
  if (response) {
   console.log(response);
   setUserDetail(response.rows[0]);
   setShowPreviewModal(true);
  } else {
   console.log('some error occured');
  }
 };
 const forumQuestionStatus = async () => {
  const response = await patchRequest('admin/forum/questions/status', {
   QuestionStatus:
    question.questionStatus.toLowerCase() === 'approve?'
     ? 'Approved'
     : 'Rejected',
   QuestionId: question.questionId,
  });
  if (response) {
   console.log(response.response);
   if (response.msg === 'success') {
    setIsModal(false);
    getForumData();
   } else {
    //setAlertModal(true);
   }
  } else {
  }
 };
 useEffect(() => {
  getForumData();
 }, []);

 return (
  <div>
   <div>
    <div className="topBarContainer">
     <div className="container">
      <div className="row">
       <SearchInput OnClick={() => getSearchItem()} />
      </div>
     </div>
    </div>

    <div className="container">
     {loading ? (
      <div id="preloaderload">
       <div className="preload-content">
        <Loader type="bubble-top" bgColor={'#005190'} size={52} />
       </div>
      </div>
     ) : forumData.length > 0 ? (
      <>
       {forumData.map((item, i) => {
        return (
         <div
          className="card newForumCard"
          style={{
           border: `2px solid ${
            item.status === 'Approved'
             ? 'green'
             : item.status === 'Rejected'
             ? 'red'
             : '#005190'
           }`,
          }}
         >
          <div className="row">
           <div className="col-md-6 questionContainer question_container">
            <div className="questionRow">
             <h4 className="QuestionNumber">Q{item.rownumber} : </h4>

             {item.type && item.type.toLowerCase() === 'audio' ? (
              <ReactAudioPlayer
               src={item.question}
               controls
               controlsList="nodownload"
              />
             ) : (
              <h4>{item.question}</h4>
             )}
            </div>
            <div className="testimonialContentMainContainer">
             {item.profile_image ? (
              <Image src={item.profile_image} />
             ) : (
              <Image src={Avatar} />
             )}
             <span className="userId">{item.username}</span>
             <button
              className="viewAll"
              onClick={() => previewCustomerDetails(item)}
             >
              User Details
             </button>
            </div>
           </div>
           <div className="col-md-3 questionContainer categoryContainer">
            <h5 className="category">Tag</h5>
            <span>{item.tag_en}</span>
            <h5 className="categoryForComment">Status</h5>
            <span
             style={{
              color:
               item.status === 'Approved'
                ? 'green'
                : item.status === 'Rejected'
                ? 'red'
                : '#005190',
             }}
            >
             {item.status}
            </span>
            <h5 className="categoryForTotalReply">Responses</h5>
            <span>{item.total_replies}</span>
           </div>
           <div className="col-md-3 questionContainer">
            <div className="buttonContainer">
             <p>{moment(item.created_at).format('YYYY-MM-DD HH:mm')}</p>
             {item.status.toLowerCase() === 'pending' ? (
              <>
               <button
                href=""
                className="viewAll"
                onClick={() => {
                 setQuestion({
                  questionId: item.question_id,
                  questionStatus: 'Reject?',
                 });
                 setIsModal(true);
                }}
               >
                Decline
               </button>

               <Button
                buttonTxt="Approve"
                OnClick={() => {
                 setQuestion({
                  questionId: item.question_id,
                  questionStatus: 'Approve?',
                 });
                 setIsModal(true);
                }}
               />
              </>
             ) : item.status.toLowerCase() === 'approved' ? (
              <button
               className="viewAll"
               onClick={() => {
                setQuestion({
                 questionId: item.question_id,
                 questionStatus: 'Reject?',
                });
                setIsModal(true);
               }}
              >
               Decline
              </button>
             ) : (
              <>
               <Button
                buttonTxt="Approve"
                OnClick={() => {
                 setQuestion({
                  questionId: item.question_id,
                  questionStatus: 'Approve?',
                 });
                 setIsModal(true);
                }}
               />
              </>
             )}
            </div>

            <div className="delete_btn deleteBtn delete">
             <Link to={{ pathname: './ViewForum', details: item }}>
              <button type="submit">View</button>
             </Link>
            </div>
           </div>
          </div>
         </div>
        );
       })}
      </>
     ) : loading ? null : (
      <div className="no-data">NO DATA FOUND</div>
     )}
     {/* )} */}
     {/* {tabs.tabTwo && (
      <div className="card newForumCard">
       <p>123</p>
      </div>
     )} */}

     <>
      {hideLoadMore || loading ? null : (
       <div className="col-12 col-lg-12 text-center">
        {loader2 ? (
         <div id="preloaderloadAreaSpecificLoader">
          <div className="preload-contentAreaSpecificLoader">
           <Loader type="bubble-top" bgColor={'#005190'} size={38} />
          </div>
         </div>
        ) : null}
        {LoadMore ? (
         <>
          <Button buttonTxt="Show More" OnClick={() => getMoreSearchItems()} />
         </>
        ) : (
         <>
          <Button
           buttonTxt="Show More"
           OnClick={() => getForumDataOnPagination()}
          />
         </>
        )}
       </div>
      )}
     </>
     {showPreviewModal ? (
      <>
       <PreviewModal
        clickCross={() => setShowPreviewModal(false)}
        UserProfle={
         customerDetails.image ? (
          <img src={customerDetails.image} alt="" className="userImg" />
         ) : (
          <img src={Avatar} alt="" className="userImg" />
         )
        }
        userDetails={userDetail}
        username={userDetail.username}
        mobile={userDetail.mobile_no}
       />
      </>
     ) : null}
     {createValidItemModal ? (
      <>
       <DeleteModal
        isSuccessModal={true}
        Onclick={() => setCreateValidItemModal(false)}
        successText="Please enter some text."
        ClickOk={() => setCreateValidItemModal(false)}
       />
      </>
     ) : null}
     {isModal && (
      <>
       <DeleteModal
        onClick={() => setIsModal(false)}
        DeleteOnclick={() => forumQuestionStatus()}
        CancelOnclick={() => setIsModal(false)}
        text={true}
        modalText={question.questionStatus}
        firstBtn="Yes"
        secondBtn="No"
       />
      </>
     )}
    </div>
   </div>
  </div>
 );
}
