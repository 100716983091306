import React, { useEffect, useState } from 'react';
import ActivityCard from '../components/ActivityCard/ActivityCard';
import { postRequest } from '../services/ApiService';
import {
 CATEGORYPREGANENTWOMEN,
 ENDINDEX,
 STARTINDEX,
} from '../libs/Constants';
import Loader from 'react-js-loader';
import DeleteModal from '../components/Modal/DeleteModal';
import SearchInput from '../components/inputFields/SearchInput';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

export default function ActivityContent() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');

 const [activityList, setActivityList] = useState([]);
 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [loading, setLoading] = useState(false);
 const [loader2, setLoader2] = useState(false);
 const [LoadMore, setLoadMore] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);

 const [tabs, setTabs] = useState({
  tabOne: true,
  tabTwo: false,
 });

 const defaultStateTabs = {
  tabOne: false,
  tabTwo: false,
 };
 const tabSwitch = (name) => {
  setTabs({ ...defaultStateTabs, [name]: true });
 };
 const getActivityData = async () => {
  setLoading(true);
  const response = await postRequest('admin/activities/get', {
   StartIndex: '1',
   EndIndex: '10',
  });
  if (response) {
   console.log(JSON.stringify(response.result[0]));
   setActivityList(response.result[0]);
   setLoading(false);
   setHideLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };
 const getSearchItem = async () => {
  setLoading(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement.length > 0) {
   const response = await postRequest('admin/activities/search', {
    SearchText: inputElement,
    StartIndex: STARTINDEX,
    EndIndex: ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setHideLoadMore(false);
    setLoadMore(true);
    setActivityList(response.result[0]);
    setLoading(false);
   } else {
    setHideLoadMore(true);
    setActivityList([]);
    setLoading(false);
   }
  } else {
   setCreateValidItemModal(true);
   setLoading(false);
  }
 };
 const getMoreActivities = async () => {
  setLoader2(true);
  const response = await postRequest('admin/activities/get', {
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result[0].length > 0) {
   setLastIndex(lastIndex + ENDINDEX);
   setActivityList([...activityList, ...response.result[0]]);
   setLoader2(false);
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };
 const getMoreSearchItems = async () => {
  setLoader2(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement) {
   const response = await postRequest('admin/users/search', {
    SearchText: inputElement,
    StartIndex: lastIndex + STARTINDEX,
    EndIndex: lastIndex + ENDINDEX,
   });
   if (response && response.result[0].length > 0) {
    setLastIndex(lastIndex + ENDINDEX);
    setActivityList([...activityList, ...response.result[0]]);
    setLoader2(false);
   } else {
    setHideLoadMore(true);
    setLoader2(false);
   }
  } else {
   setLoader2(false);
  }
 };
 useEffect(() => {
  getActivityData();
 }, []);
 return (
  <div>
   <div className="topBarContainer">
    <div className="container">
     <div className="row">
      <SearchInput OnClick={() => getSearchItem()} />
      <Button
       topBarButton={true}
       smallButton={true}
       navigateTo={'./activityAdd'}
       LableTxt="Add"
      />
     </div>
    </div>
   </div>
   <div className="container">
    <div className="row">
     <div className="col-md-2">
      <button
       onClick={() => tabSwitch('tabOne')}
       className={tabs.tabOne ? 'tab active' : 'tab'}
      >
       English
      </button>
     </div>
     <div className="col-md-2">
      <button
       onClick={() => tabSwitch('tabTwo')}
       className={tabs.tabTwo ? 'tab active' : 'tab'}
      >
       Hindi
      </button>
     </div>
    </div>
   </div>
   {loading ? (
    <div id="preloaderload">
     <div className="preload-content">
      <Loader type="bubble-top" bgColor={'#005190'} size={52} />
     </div>
    </div>
   ) : activityList.length > 0 ? (
    <div className="container">
     {tabs.tabOne && (
      <>
       {activityList.map((item, i) => {
        return (
         <ActivityCard
          language="English"
          category={item.category}
          category_e={
           item.category === CATEGORYPREGANENTWOMEN
            ? 'Pregnant Women'
            : 'Parents'
          }
          tag_e={item.tag_en}
          title_e={item.title_en}
          image_e={item.activity_image_en}
          agegroup={item.agegroup}
          actionType="View Details"
          path="./ActivityDetail"
          id={item.activityid}
          activityViews={item.total_completion_views}
         />
        );
       })}
      </>
     )}
     {tabs.tabTwo && (
      <>
       {activityList.map((item, i) => {
        return (
         <ActivityCard
          language="Hindi"
          category={item.category}
          category_h={
           item.category === CATEGORYPREGANENTWOMEN ? 'गर्भवती औरत' : 'अभिभावक'
          }
          tag_h={item.tag_h}
          title_h={item.title_h}
          image_h={item.activity_image_h}
          agegroup={item.agegroup}
          actionType="View Details"
          path="./ActivityDetail"
          id={item.activityid}
         />
        );
       })}
      </>
     )}
    </div>
   ) : loading ? null : (
    <div className="no-data">NO DATA FOUND</div>
   )}
   {createValidItemModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setCreateValidItemModal(false)}
      successText="Please enter some text."
      ClickOk={() => setCreateValidItemModal(false)}
     />
    </>
   ) : null}
   <>
    {hideLoadMore || loading ? null : (
     <div className="col-12 col-lg-12 text-center">
      {loader2 ? (
       <div id="preloaderloadAreaSpecificLoader">
        <div className="preload-contentAreaSpecificLoader">
         <Loader type="bubble-top" bgColor={'#005190'} size={38} />
        </div>
       </div>
      ) : null}
      {LoadMore ? (
       <>
        <Button buttonTxt="Show More" OnClick={() => getMoreSearchItems()} />
       </>
      ) : (
       <>
        <Button buttonTxt="Show More" OnClick={() => getMoreActivities()} />
       </>
      )}
     </div>
    )}
   </>
  </div>
 );
}
