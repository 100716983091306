import React from 'react';
import '../../assets/css/style.css';

import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';
import SearchIcon from '../../assets/images/searchIcon.png';

const SearchInput = (props) => {
 return (
  <div className="col-md-6 searchContainer search">
   <input
    type="text"
    placeholder="Search for content"
    className="searchTextArea"
    id="searchText"
   />
   <img
    src={SearchIcon}
    className="searchIcon"
    alt=""
    onClick={props.OnClick}
   />
  </div>
 );
};
export default SearchInput;
