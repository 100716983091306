import React from 'react';
import '../../assets/css/style.css';
import Loader from 'react-js-loader';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';
const Button = (props) => {
 return (
  <>
   {props.topBarButton ? (
    <div className="col d-flex justify-content-end">
     <a href={props.navigateTo}>
      <button
       className={
        props.smallButton ? 'downloadBtn updateBtn' : 'downloadBtn deleteBtn'
       }
       onClick={props.buttonOnClick}
       style={{ marginTop: '0px' }}
      >
       {props.LableTxt}
      </button>
     </a>
    </div>
   ) : (
    <div className="delete_btn">
     {props.loading ? (
      <div className="sendButtonLoader">
       <div className="preload-contentAreaSpecificLoader">
        <Loader type="bubble-top" bgColor={'#005190'} size={24} />
       </div>
      </div>
     ) : null}
     <button type="submit" onClick={props.OnClick}>
      {props.buttonTxt}
     </button>
    </div>
   )}
  </>
 );
};
export default Button;
