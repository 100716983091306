import { Route, Switch } from 'react-router-dom';
import Customers from '../pages/Customers';
import login from '../pages/login';
import Analytics from '../pages/Analytics';
import FeedbackData from '../pages/FeedbackData';
import ForumData from '../pages/ForumData';
import ViewForum from '../pages/ViewForum';
import AddActivity from '../pages/AddActivity';
import UpdateActivity from '../pages/UpdateActivity';
import ActivityContent from '../pages/ActivityContent';
import ActivityDetail from '../pages/ActivityDetail';
import Generatemessage from '../pages/Generatemessage';
import AddResources from '../pages/AddResources';
import Resources from '../pages/Resources';

const Routes = () => {
 return (
  <Switch>
   <Route path="/" exact component={login} />
   <Route path="/customers" component={Customers} />
   <Route path="/activityContent" component={ActivityContent} />
   <Route path="/analytics" component={Analytics} />
   <Route path="/feedbackData" component={FeedbackData} />
   <Route path="/forumData" component={ForumData} />
   <Route path="/activityAdd" component={AddActivity} />
   <Route path="/activityDetail" component={ActivityDetail} />
   <Route path="/activityUpdate" component={UpdateActivity} />
   <Route path="/sendNotification" component={Generatemessage} />
   <Route path="/addResources" component={AddResources} />
   <Route path="/Resources" component={Resources} />
   <Route path="/ViewForum" component={ViewForum} />
  </Switch>
 );
};

export default Routes;
