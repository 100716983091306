import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './sidebar.css';

import logo from '../../assets/logo.png';
import Button from '../buttons/Button';
import sidebar_items from '../../assets/JsonData/sidebar_routes.json';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SidebarItem = (props) => {
 const active = props.active ? 'active' : '';

 return (
  <div className="sidebar__item">
   <div className={`sidebar__item-inner ${active}`}>
    <i className={props.icon}></i>
    <span>{props.title}</span>
   </div>
  </div>
 );
};

const Sidebar = (props) => {
 const history = useHistory();

 const activeItem = sidebar_items.findIndex(
  (item) => item.route === props.location.pathname
 );

 const logout = () => {
  sessionStorage.clear();
  history.push('/');
 };
 return (
  <div className="sidebar">
   <div className="sidebar__logo">
    <div className="row">
     <div className="col-12">
      <div className="companyimg">
       <img src={logo} alt="company logo" />
      </div>
     </div>
    </div>
   </div>
   {sidebar_items.map((item, index) => (
    <Link to={item.route} key={index}>
     <SidebarItem
      title={item.display_name}
      icon={item.icon}
      active={index === activeItem}
     />
    </Link>
   ))}
   <Button OnClick={() => logout()} buttonTxt="LOG OUT" />
  </div>
 );
};

export default Sidebar;
