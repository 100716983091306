import { useEffect, useState } from 'react';
import cross from '../assets/crossicon.png';
import { deleteRequest, postRequest } from '../services/ApiService';
import { ENDINDEX, STARTINDEX } from '../libs/Constants';
import YouTube from 'react-youtube';
import ReactAudioPlayer from 'react-audio-player';
import { Document, Page } from 'react-pdf';
import Loader from 'react-js-loader';
import DeleteModal from '../components/Modal/DeleteModal';
import SearchInput from '../components/inputFields/SearchInput';
import Button from '../components/buttons/Button';
import { useHistory } from 'react-router-dom';

export default function Resources() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [showPreviewModal, setShowPreviewModal] = useState(false);
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [showDeleteModal, setShowDeleteModal] = useState(false);
 const [resourceId, setResourceId] = useState(0);
 const [loading, setLoading] = useState(false);
 const [resourcesData, setResourcesData] = useState([]);
 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [LoadMore, setLoadMore] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [loader2, setLoader2] = useState(false);

 const [url] = useState({ urlType: '', urlId: '' });
 const [numPages, setNumPages] = useState(null);
 const [pageNumber] = useState(1);

 const onDocumentLoadSuccess = ({ numPages }) => {
  setNumPages(numPages);
  console.log('numPages', numPages);
 };

 const getResources = async () => {
  setLoading(true);
  const response = await postRequest('admin/resources/get', {
   StartIndex: STARTINDEX,
   EndIndex: ENDINDEX,
  });
  if (response) {
   console.log(JSON.stringify(response.result[0]));
   setResourcesData(response.result[0]);
   setLoading(false);
   setHideLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };
 const getResourcesDataOnPagination = async () => {
  setLoader2(true);
  const response = await postRequest('admin/resources/get', {
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result[0].length > 0) {
   setLastIndex(lastIndex + ENDINDEX);
   setResourcesData([...resourcesData, ...response.result[0]]);
   setLoader2(false);
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };
 const getSearchItem = async () => {
  setLoading(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement.length > 0) {
   const response = await postRequest('admin/resources/search', {
    SearchText: inputElement,
    StartIndex: STARTINDEX,
    EndIndex: ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setHideLoadMore(false);
    setLoadMore(true);
    setResourcesData(response.result[0]);
    setLoading(false);
   } else {
    setHideLoadMore(true);
    setResourcesData([]);
    setLoading(false);
   }
  } else {
   setCreateValidItemModal(true);
   setLoading(false);
  }
 };
 const getMoreSearchItems = async () => {
  setLoader2(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement) {
   const response = await postRequest('admin/resources/search', {
    SearchText: inputElement,
    StartIndex: lastIndex + STARTINDEX,
    EndIndex: lastIndex + ENDINDEX,
   });
   if (response && response.result[0].length > 0) {
    setLastIndex(lastIndex + ENDINDEX);
    setResourcesData([...resourcesData, ...response.result[0]]);
    setLoader2(false);
   } else {
    setHideLoadMore(true);
    setLoader2(false);
   }
  } else {
   setLoader2(false);
  }
 };
 const sendNotification = async (
  heading_en,
  heading_h,
  url_en,
  url_h,
  resource_type
 ) => {
  const response = await postRequest('admin/notifications/add', {
   Category: 3,
   Type: 'Resource',
   Image: url_en,
   Content_En: heading_en,
   Content_H: heading_h,
   Activity_Id: '',
   ResourceType: resource_type,
   Desciption: '',
  });
  if (response) {
   console.log(JSON.stringify(response));
   const responseNotification = await postRequest(
    'admin/notifications/send/topic',
    {
     title: heading_en,
     body: heading_h,
     topicname: 'All',
    }
   );
   if (responseNotification) {
    alert('Notification send successfully');
    setLoading(false);
   } else {
    alert('Some error occurred');
    setLoading(false);
   }
  } else {
   //    setLoading(false);
  }
 };
 const _onReady = (event) => {
  event.target.pauseVideo();
 };

 const deleteData = async () => {
  const response = await deleteRequest('admin/resources/delete', {
   ResourceId: resourceId,
  });
  if (response) {
   console.log(response);
   if (response.msg === 'success') {
    setShowDeleteModal(false);
    setShowSuccessModal(true);
    getResources();
   } else {
   }
  } else {
  }
 };
 const opts = {
  height: '200',
  width: '200',
  playerVars: {
   autoplay: 1,
  },
 };

 useEffect(() => {
  getResources();
 }, []);
 return (
  <div>
   <div className="topBarContainer">
    <div className="container">
     <div className="row">
      <SearchInput OnClick={() => getSearchItem()} />
      <Button
       topBarButton={true}
       navigateTo={'./AddResources'}
       LableTxt="Add Resource"
      />
     </div>
    </div>
   </div>
   <div className="row">
    <div className="col-md-12">
     {loading ? (
      <div id="preloaderload">
       <div className="preload-content">
        <Loader type="bubble-top" bgColor={'#005190'} size={52} />
       </div>
      </div>
     ) : (
      <table>
       <thead>
        <tr className="resourcesTableHead">
         <th>Title English</th>
         <th>Title Hindi</th>
         <th>URL English</th>
         <th>URL Hindi</th>
         <th>Resource Type</th>
         <th></th>
         <th></th>
        </tr>
       </thead>
       {resourcesData.length > 0 ? (
        <>
         {resourcesData.map((item, i) => {
          return (
           <tr>
            <td>{item.resource_heading_en}</td>
            <td>{item.resource_heading_h}</td>
            <td>
             <a
              href={item.resource_url_en}
              target="_blank"
              className="urls"
              rel="noreferrer"
             >
              {item.resource_url_en}
             </a>
            </td>
            <td>
             <a
              href={item.resource_url_h}
              target="_blank"
              className="urls"
              rel="noreferrer"
             >
              {item.resource_url_h}
             </a>
            </td>
            <td>{item.resource_type}</td>
            <td>
             <div
              className="deleteuser"
              onClick={() => {
               sendNotification(
                item.resource_heading_en,
                item.resource_heading_h,
                item.resource_url_en,
                item.resource_url_h,
                item.resource_type
               );
              }}
             >
              Send Notification
             </div>
            </td>
            <td>
             <div
              className="deleteuser"
              onClick={() => {
               setShowDeleteModal(true);
               setResourceId(item.resource_id);
              }}
             >
              Delete
             </div>
            </td>
           </tr>
          );
         })}
        </>
       ) : loading ? null : (
        <tr>
         <td colSpan={6}>
          <div className="no-data">NO DATA FOUND</div>
         </td>
        </tr>
       )}
      </table>
     )}
     <>
      {hideLoadMore || loading ? null : (
       <div className="col-12 col-lg-12 text-center">
        {loader2 ? (
         <div id="preloaderloadAreaSpecificLoader">
          <div className="preload-contentAreaSpecificLoader">
           <Loader type="bubble-top" bgColor={'#005190'} size={38} />
          </div>
         </div>
        ) : null}
        {LoadMore ? (
         <>
          <Button buttonTxt="Show More" OnClick={() => getMoreSearchItems()} />
         </>
        ) : (
         <>
          <Button
           buttonTxt="Show More"
           OnClick={() => getResourcesDataOnPagination()}
          />
         </>
        )}
       </div>
      )}
     </>
    </div>
   </div>
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Deleted Successfully"
      ClickOk={() => setShowSuccessModal(false)}
     />
    </>
   ) : null}
   {showDeleteModal ? (
    <>
     <DeleteModal
      onClick={() => setShowDeleteModal(false)}
      DeleteOnclick={() => deleteData()}
      CancelOnclick={() => setShowDeleteModal(false)}
      modalText="Delete 1 Resource?"
      firstBtn="Delete"
      secondBtn="Cancel"
     />
    </>
   ) : null}
   {showPreviewModal ? (
    <div className="modalOverlay">
     <div className="requestModal previewModal">
      <span
       className="crossBtnContainer"
       onClick={() => setShowPreviewModal(false)}
      >
       {' '}
       <img src={cross} alt="" />
      </span>
      {url.urlType === 'Video' ? (
       <YouTube videoId={url.urlId} opts={opts} onReady={_onReady} />
      ) : url.urlType === 'Audio' ? (
       <ReactAudioPlayer src={url.urlId} controls controlsList="nodownload" />
      ) : (
       <>
        <Document
         file="https://firebasestorage.googleapis.com/v0/b/paalanapp-1c31e.appspot.com/o/pdf%2FJourney_of_The_First_1000_Days.pdf?alt=media&token=d6837270-9292-41b5-a982-e03b5123cad0"
         onLoadSuccess={onDocumentLoadSuccess}
        >
         <Page pageNumber={pageNumber} />
        </Document>
        <p>
         Page {pageNumber} of {numPages}
        </p>
       </>
      )}
     </div>
    </div>
   ) : null}
   {createValidItemModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setCreateValidItemModal(false)}
      successText="Please enter some text."
      ClickOk={() => setCreateValidItemModal(false)}
     />
    </>
   ) : null}
  </div>
 );
}
