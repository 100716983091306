import React, { useState } from 'react';
import '../assets/css/table.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { postRequest } from '../services/ApiService';
import DeleteModal from '../components/Modal/DeleteModal';
import TextInput from '../components/inputFields/TextInput.jsx';
import Button from '../components/buttons/Button';
const AddResources = (params) => {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [category, setCategory] = useState('Audio');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [showAlertModal, setShowAlertModal] = useState({
  status: false,
  text: '',
 });
 const [showErrorAlertModal, setshowErrorAlertModal] = useState(false);
 const [loading, setLoading] = useState(false);

 function handleOnclick() {
  history.push('./Resources');
 }
 const addResource = async () => {
  setLoading(true);
  const titleEnglish = document.getElementById('titleEnglish').value;
  const titleHindi = document.getElementById('titleHindi').value;
  const urlEnglish = document.getElementById('urlEnglish').value;
  const urlHindi = document.getElementById('urlHindi').value;
  console.log(category);
  console.log(
   (urlEnglish.includes('https://www.youtube.com/watch?') &&
    urlHindi.includes('https://www.youtube.com/watch?') &&
    category.includes('Video')) ||
    category.includes('Audio') ||
    category.includes('PDF')
  );
  if (titleEnglish && titleHindi && urlEnglish && urlHindi) {
   if (
    (urlEnglish.includes('https://www.youtube.com/watch?') &&
     urlHindi.includes('https://www.youtube.com/watch?') &&
     category.includes('Video')) ||
    category.includes('Audio') ||
    category.includes('PDF')
   ) {
    const response = await postRequest('admin/resources/add', {
     Type: category,
     Title_E: titleEnglish,
     Title_H: titleHindi,
     Url_E: urlEnglish,
     Url_H: urlHindi,
    });
    if (response) {
     console.log(JSON.stringify(response));
     setLoading(false);
     setShowSuccessModal(true);
     document.getElementById('titleEnglish').value = '';
     document.getElementById('titleHindi').value = '';
     document.getElementById('urlEnglish').value = '';
     document.getElementById('urlHindi').value = '';
    } else {
     setshowErrorAlertModal(true);
    }
   } else {
    setLoading(false);
    setShowAlertModal({
     status: true,
     text: 'Please enter valid link.',
    });
   }
  } else {
   setLoading(false);
   setShowAlertModal({ status: true, text: 'Please enter all input fields.' });
  }
 };

 return (
  <div>
   <div
    style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}
   >
    <div className="card previewcard createEvent-card">
     <div className="generatetexttitle">
      <h2>ADD RESOURCES</h2>
     </div>
     <TextInput Id="titleEnglish" Placeholder="Title English" MaxLength={100} />
     <TextInput Id="titleHindi" Placeholder="Title Hindi" MaxLength={100} />
     <TextInput Id="urlEnglish" Placeholder="URL English" MaxLength={300} />
     <TextInput Id="urlHindi" Placeholder="URL Hindi" MaxLength={300} />
     <div
      className="generatetextbody generateTextBody "
      style={{ marginTop: '14px' }}
     >
      <label className="form-label">Resource Type</label>
      <select
       className="form-select form-control Select-body"
       aria-label="Default select example"
       onChange={(event) => setCategory(event.target.value)}
      >
       <option value="Audio" className="form-control">
        Audio
       </option>
       <option value="Video" className="form-control">
        Video
       </option>
       <option value="PDF" className="form-control">
        PDF
       </option>
      </select>
     </div>
     {/* <SelectField
      Lable="Resource Type"
      OnChange={(event) => setCategory(event.target.value)}
      option1="Audio"
      option2="Video"
      option3="PDF"
     /> */}
     <>
      <Button buttonTxt="Add" OnClick={() => addResource()} loading={loading} />
     </>
    </div>
   </div>
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Added Successfully"
      ClickOk={() => handleOnclick()}
     />
    </>
   ) : null}
   {showErrorAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setshowErrorAlertModal(false)}
      successText="Some error Occured"
      ClickOk={() => setshowErrorAlertModal(false)}
     />
    </>
   ) : null}
   {showAlertModal.status ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowAlertModal({ status: false })}
      successText={showAlertModal.text}
      ClickOk={() => setShowAlertModal({ status: false })}
     />
    </>
   ) : null}
  </div>
 );
};

export default AddResources;
