import { useState, useEffect } from 'react';
import Avatar from '../assets/images/profileimg.png';
import Loader from 'react-js-loader';
import { getRequest, deleteRequest, postRequest } from '../services/ApiService';
import {
 CATEGORYPARENTS,
 CATEGORYPREGANENTWOMEN,
 CATEGORYHEALTHCAREWORKER,
 ENDINDEX,
 STARTINDEX,
} from '../libs/Constants';
import moment from 'moment';
import DeleteModal from '../components/Modal/DeleteModal';
import PreviewModal from '../components/Modal/PreviewModal';
import SearchInput from '../components/inputFields/SearchInput';
import Button from '../components/buttons/Button';
import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate';
import { useHistory } from 'react-router-dom';
import { DateInput } from 'react-admin';
import TextInput from '../components/inputFields/TextInput';
const Products = (params) => {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [userData, setUserData] = useState([]);
 const [totalUsers, setTotalUsers] = useState([]);
 const [showValidateModal, setShowValidateModal] = useState(false);
 const [showPreviewModal, setShowPreviewModal] = useState(false);
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [userId, setUserId] = useState(0);
 const [loading, setLoading] = useState(false);
 const [loader2, setLoader2] = useState(false);
 const [lastIndex, setLastIndex] = useState(ENDINDEX);
 const [createValidItemModal, setCreateValidItemModal] = useState(false);
 const [customerDetails, setCustomerDetails] = useState({
  image: '',
  mobile: '',
  username: '',
 });
 const dateObj = new Date();
 const newDate = moment(dateObj).format('YYYY-MM-DD');
 const [userDate, setUserDate] = useState({
  StartDate: '2022-09-23',
  EndDate: newDate,
 });

 const [LoadMore, setLoadMore] = useState(false);
 const [hideLoadMore, setHideLoadMore] = useState(false);
 const getUserData = async () => {
  setLoading(true);
  const response = await postRequest('admin/users/get', {
   StartIndex: STARTINDEX,
   EndIndex: ENDINDEX,
  });
  if (response) {
   console.log(response.result[1][0].total_users);
   setTotalUsers(response.result[1][0].total_users);
   setUserData(response.result[0]);
   setLoading(false);
   setHideLoadMore(false);
  } else {
   setHideLoadMore(true);
   setLoading(false);
  }
 };
 const getUserDataOnPagination = async () => {
  setLoader2(true);
  const response = await postRequest('admin/users/get', {
   StartIndex: lastIndex + STARTINDEX,
   EndIndex: lastIndex + ENDINDEX,
  });
  if (response && response.result) {
   if (response.result[0].length > 0) {
    setLastIndex(lastIndex + ENDINDEX);
    setUserData([...userData, ...response.result[0]]);
    setLoader2(false);
   } else {
    setHideLoadMore(true);
    setLoader2(false);
   }
  } else {
   setHideLoadMore(true);
   setLoader2(false);
  }
 };

 const showValidation = async (id) => {
  setUserId(id);
  setShowValidateModal(true);
 };

 const deleteData = async (userid) => {
  const response = await deleteRequest('admin/users/delete', {
   UserId: userid,
  });
  if (response) {
   console.log(response);
   if (response.msg === 'success') {
    setShowSuccessModal(true);
    setShowValidateModal(false);

    getUserData();
   } else {
   }
  } else {
  }
 };

 const getSearchItem = async () => {
  setLoading(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement.length > 0) {
   const response = await postRequest('admin/users/search', {
    SearchText: inputElement,
    StartIndex: STARTINDEX,
    EndIndex: ENDINDEX,
   });
   console.log(response);
   if (response && response.result[0].length > 0) {
    setHideLoadMore(false);
    setLoadMore(true);
    setUserData(response.result[0]);
    setLoading(false);
   } else {
    setHideLoadMore(true);
    setUserData([]);
    setLoading(false);
   }
  } else {
   setCreateValidItemModal(true);
   setLoading(false);
  }
 };
 const getMoreSearchItems = async () => {
  setLoader2(true);
  const inputElement = document.getElementById('searchText').value;
  if (inputElement) {
   const response = await postRequest('admin/users/search', {
    SearchText: inputElement,
    StartIndex: lastIndex + STARTINDEX,
    EndIndex: lastIndex + ENDINDEX,
   });
   if (response.result) {
    if (response.result[0].length > 0) {
     setLastIndex(lastIndex + ENDINDEX);
     setUserData([...userData, ...response.result[0]]);
     setLoader2(false);
    } else {
     setHideLoadMore(true);
     setLoader2(false);
    }
   } else {
    setLoader2(false);
   }
  }
 };
 const previewCustomerDetails = (
  customerImage,
  customerUsername,
  customerMobile
 ) => {
  setCustomerDetails({
   image: customerImage,
   mobile: customerMobile,
   username: customerUsername,
  });
  setShowPreviewModal(true);
 };

 const onPageScroll = () => {
  if (LoadMore) getMoreSearchItems();
  else getUserDataOnPagination();
 };

 function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
   return fields.map(function (fieldName) {
    return row[fieldName] ? row[fieldName] : '';
   });
  });
  sheetData.unshift(header);
  return sheetData;
 }
 async function AllSaveAsExcel() {
  setLoading(true);
  const response = await getRequest('admin/users/download');
  if (response) {
   console.log('userlist', response.rows);
   const userList = response.rows;
   if (userList.length > 0) {
    const data = userList.map((item) => ({
     username: item.username,
     mobileno: item.mobile,
     registrationdate: item.createdat
      ? moment(item.createdat).format('DD-MM-YYYY hh:mm:ss')
      : null,
     category:
      item.category == 1
       ? 'Pregnant Women'
       : item.category == 2
       ? 'Parents'
       : item.category == 3
       ? 'Health Worker'
       : null,
     lmp: item.lmp ? moment(item.lmp).format('DD-MM-YYYY') : null,
     age: item.age,
     childname: item.childname,
     gender: item.gender == 1 ? 'Boy' : item.gender == 2 ? 'Girl' : null,
     dob: item.dob ? moment(item.dob).format('DD-MM-YYYY') : null,
     relationship: item.relationship,
     language: item.language,
    }));

    // console.log(data);
    let header = [
     'Username',
     'MobileNo',
     'RegistrationDate',
     'Category',
     'LastMentrualPeriod',
     'WomenAge',
     'ChileName',
     'ChildGender',
     'ChildDateOfBirth',
     'RelationshipWithChild',
     'LanguageSelected',
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
     const sheet1 = workbook.sheet(0);
     const sheetData = getSheetData(data, header);
     const totalColumns = sheetData[0].length;

     sheet1.cell('A1').value(sheetData);
     const range = sheet1.usedRange();
     const endColumn = String.fromCharCode(64 + totalColumns);
     sheet1.row(1).style('bold', true);
     sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');
     range.style('border', true);
     return workbook.outputAsync().then((res) => {
      saveAs(res, 'file.xlsx');
     });
    });
    setLoading(false);
   }
  } else {
   setLoading(false);
  }
 }
 async function getUserByDate (){
  setLoading(true);
  console.log(newDate);
  console.log(userDate.from < '2022-09-23');
  if (userDate) {
   const response = await postRequest('admin/users/by/date/get', {
    StartDate:
     userDate.StartDate > '2022-09-23'
      ? moment(userDate.StartDate).format('YYYY-MM-DD')
      : moment('2022-09-23').format('YYYY-MM-DD'),
    EndDate: userDate.EndDate
     ? moment(userDate.EndDate).format('YYYY-MM-DD')
     : moment(newDate).format('YYYY-MM-DD'),
   });
   if (response) {
    setHideLoadMore(true);
    console.log('userlist', response.rows);
    const userList = response.rows;
    setUserData(userList);
    setLoading(false)}}
 }
 async function saveAsExcel() {
  setLoading(true);
  console.log(newDate);
  console.log(userDate.from < '2022-09-23');
  if (userDate) {
   const response = await postRequest('admin/users/by/date/get', {
    StartDate:
     userDate.StartDate > '2022-09-23'
      ? moment(userDate.StartDate).format('YYYY-MM-DD')
      : moment('2022-09-23').format('YYYY-MM-DD'),
    EndDate: userDate.EndDate
     ? moment(userDate.EndDate).format('YYYY-MM-DD')
     : moment(newDate).format('YYYY-MM-DD'),
   });

   if (response) {
    console.log('userlist', response.rows);
    const userList = response.rows;
    setUserData(userList);
    if (userList.length > 0) {
     const data = userList.map((item) => ({
      username: item.username,
      mobileno: item.mobile,
      registrationdate: item.createdat
       ? moment(item.createdat).format('DD-MM-YYYY hh:mm:ss')
       : null,
      category:
       item.category == 1
        ? 'Pregnant Women'
        : item.category == 2
        ? 'Parents'
        : item.category == 3
        ? 'Health Worker'
        : null,
      lmp: item.lmp ? moment(item.lmp).format('DD-MM-YYYY') : null,
      age: item.age,
      childname: item.childname,
      gender: item.gender == 1 ? 'Boy' : item.gender == 2 ? 'Girl' : null,
      dob: item.dob ? moment(item.dob).format('DD-MM-YYYY') : null,
      relationship: item.relationship,
      language: item.language,
     }));

     // console.log(data);
     let header = [
      'Username',
      'MobileNo',
      'RegistrationDate',
      'Category',
      'LastMentrualPeriod',
      'WomenAge',
      'ChileName',
      'ChildGender',
      'ChildDateOfBirth',
      'RelationshipWithChild',
      'LanguageSelected',
     ];

     XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell('A1').value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');
      range.style('border', true);
      return workbook.outputAsync().then((res) => {
       saveAs(res, 'file.xlsx');
      });
     });
     setLoading(false);
    }
   } else {
    setLoading(false);
   }
  } else {
   const data = userData.map((item) => ({
    username: item.username,
    mobileno: item.mobile,
    registrationdate: item.createdat
     ? moment(item.createdat).format('DD-MM-YYYY hh:mm:ss')
     : null,
    category:
     item.category == 1
      ? 'Pregnant Women'
      : item.category == 2
      ? 'Parents'
      : item.category == 3
      ? 'Health Worker'
      : null,
    lmp: item.lmp ? moment(item.lmp).format('DD-MM-YYYY') : null,
    age: item.age,
    childname: item.childname,
    gender: item.gender == 1 ? 'Boy' : item.gender == 2 ? 'Girl' : null,
    dob: item.dob ? moment(item.dob).format('DD-MM-YYYY') : null,
    relationship: item.relationship,
    language: item.language,
   }));

   // console.log(data);
   let header = [
    'Username',
    'MobileNo',
    'RegistrationDate',
    'Category',
    'LastMentrualPeriod',
    'WomenAge',
    'ChileName',
    'ChildGender',
    'ChildDateOfBirth',
    'RelationshipWithChild',
    'LanguageSelected',
   ];

   XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(data, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell('A1').value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style('bold', true);
    sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');
    range.style('border', true);
    return workbook.outputAsync().then((res) => {
     saveAs(res, 'file.xlsx');
    });
   });
   setLoading(false);
  }
 }

 useEffect(() => {
  getUserData();
  // deleteData();
 }, []);
 console.log('userdata', userData);
 return (
  <div>
   <div className="topBarContainer top_bar_container">
    <div className="container">
     <div className="row download_bar">
      <SearchInput OnClick={() => getSearchItem()} />
     </div>
    </div>
   </div>

   <div className="row download">
    <div
     className="col-md-3 mb-2"
     style={{ display: 'flex', alignItems: 'center' }}
    >
     <label for="date-picker" class="form-label" className="form-label1">
      From
     </label>
     <TextInput
      dateInputField={true}
      DatePicker={true}
      id="date-picker"
      Value={userDate.StartDate}
      OnChange={(event) =>
       setUserDate({ ...userDate, StartDate: event.target.value })
      }
     />
    </div>
    <div
     className="col-md-3 mb-2"
     style={{ display: 'flex', alignItems: 'center' }}
    >
     <label for="date-picker" class="form-label" className="form-label1">
      To
     </label>
     <TextInput
      dateInputField={true}
      DatePicker={true}
      id="date-picker"
      Value={userDate.EndDate}
      OnChange={(event) =>
       setUserDate({ ...userDate, EndDate: event.target.value })
      }
     />
    </div>
    <div className="col-md-2 mb-2">
     <Button
      topBarButton={true}
      smallButton={true}
      LableTxt="Submit"
      buttonOnClick={() => {
        getUserByDate();
      }}
     />
    </div>
    <div className="col-md-2 mb-2">
     <Button
      topBarButton={true}
      smallButton={true}
      LableTxt="Download"
      buttonOnClick={() => {
       saveAsExcel();
      }}
     />
    </div>
    <div className="col-md-2 mb-2">
     <Button
      topBarButton={true}
      smallButton={true}
      LableTxt="Download All"
      buttonOnClick={() => {
       AllSaveAsExcel();
      }}
     />
    </div>
   </div>
   <p className="totalUsers">
    {' '}
    1 - {lastIndex} of {totalUsers}{' '}
   </p>
   <div
    className="row"
    // style={{ overflow: 'auto', display: 'inline-table' }}
    // onScroll={() => onPageScroll()}
    style={{ height: 500, overflowY: 'scroll' }}
   >
    <div className="col-12">
     <table>
      {loading ? null : (
       <thead
        className="lome table-heading"
        // style={{
        //  position: 'fixed',
        // }}
       >
        <tr>
         <th>Username</th>
         <th>Mobile Number</th>
         <th>Registration Date</th>
         <th>Category</th>
         <th>Last Menstrual Period</th>
         <th>Age</th>
         <th>Child Name</th>
         <th>Gender</th>
         <th>Date Of Birth</th>
         <th>Relationship</th>
         <th>Language</th>
         <th>Profile Photo</th>
         <th></th>
        </tr>
       </thead>
      )}

      {loading ? (
       <div id="preloaderload">
        <div className="preload-content">
         <Loader type="bubble-top" bgColor={'#005190'} size={52} />
        </div>
       </div>
      ) : userData.length > 0 ? (
       <>
        {userData.map((item, i) => {
         return (
          <tr key={i}>
           <td>{item.username}</td>
           <td>{item.mobile}</td>
           <td>
            {item.createdat
             ? moment(item.createdat).format('DD-MM-YYYY HH:mm')
             : null}
           </td>
           <td>
            {item.category === CATEGORYPREGANENTWOMEN
             ? 'Pregnant Women'
             : item.category === CATEGORYPARENTS
             ? 'Parents'
             : item.category === CATEGORYHEALTHCAREWORKER
             ? 'Healthcare Worker'
             : null}
           </td>
           <td>{item.lmp ? moment(item.lmp).format('DD-MM-YYYY') : null}</td>

           <td>{item.age}</td>
           <td>{item.childname}</td>
           <td>
            {item.gender == 1 ? 'Boy' : item.gender == 2 ? 'Girl' : null}
           </td>
           <td>{item.dob ? moment(item.dob).format('DD-MM-YYYY') : null}</td>
           <td>{item.relationship}</td>
           <td>{item.language}</td>

           <td>
            <div
             className="previewuser"
             //  onClick={() => showPreview(item.userid)}
             onClick={() =>
              previewCustomerDetails(item.image, item.username, item.mobile)
             }
            >
             Preview
            </div>
           </td>
           <td>
            <div
             className="deleteuser"
             onClick={() => showValidation(item.userid)}
            >
             Delete
            </div>
           </td>
          </tr>
         );
        })}
       </>
      ) : loading ? null : (
       <tr>
        <td colSpan={14}>
         <div className="no-data">NO DATA FOUND</div>
        </td>
       </tr>
      )}
     </table>

     {showValidateModal ? (
      <>
       <DeleteModal
        onClick={() => setShowValidateModal(false)}
        DeleteOnclick={() => deleteData(userId)}
        CancelOnclick={() => setShowValidateModal(false)}
        modalText="Delete 1 User?"
        firstBtn="Delete"
        secondBtn="Cancel"
       />
      </>
     ) : null}
     {showSuccessModal ? (
      <>
       <DeleteModal
        isSuccessModal={true}
        Onclick={() => setShowSuccessModal(false)}
        successText="Deleted Successfully"
        ClickOk={() => setShowSuccessModal(false)}
       />
      </>
     ) : null}

     {createValidItemModal ? (
      <>
       <DeleteModal
        isSuccessModal={true}
        Onclick={() => setCreateValidItemModal(false)}
        successText="Please enter some text."
        ClickOk={() => setCreateValidItemModal(false)}
       />
      </>
     ) : null}
     {showPreviewModal ? (
      <>
       <PreviewModal
        clickCross={() => setShowPreviewModal(false)}
        UserProfle={
         customerDetails.image ? (
          <img src={customerDetails.image} alt="" className="userImg" />
         ) : (
          <img src={Avatar} alt="" className="userImg" />
         )
        }
        username={customerDetails.username}
        mobile={customerDetails.mobile}
       />
      </>
     ) : null}
    </div>
   </div>
   <>
    {hideLoadMore || loading ? null : (
     <div className="col-12 col-lg-12 text-center">
      {loader2 ? (
       <div id="preloaderloadAreaSpecificLoader">
        <div className="preload-contentAreaSpecificLoader">
         <Loader type="bubble-top" bgColor={'#005190'} size={38} />
        </div>
       </div>
      ) : null}
      {LoadMore ? (
       <>
        <Button buttonTxt="Show More" OnClick={() => getMoreSearchItems()} />
       </>
      ) : (
       <>
        <Button
         buttonTxt="Show More"
         OnClick={() => getUserDataOnPagination()}
        />
       </>
      )}
     </div>
    )}
   </>
  </div>
 );
};

export default Products;
