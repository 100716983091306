import React from 'react';
import Loader from 'react-js-loader';
import '../../assets/css/style.css';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';
const ActivityDetailFields = (props) => {
 return (
  <div style={{ paddingTop: '20px' }}>
   <div className="container">
    <div className="card notificationCard w-100">
     <form>
      <div className="row">
       <div className="col-md-6 mb-3">
        <label className="form-label">Category</label>
        <select
         className="form-select"
         aria-label="Default select example"
         onChange={(event) => props.EventHandler1(event.target.value)}
         value={props.Value1}
        >
         <option value="1" className="form-control">
          Pregnant Women
         </option>
         <option value="2" className="form-control">
          Parents
         </option>
        </select>
       </div>
       <div className="col-md-6">
        <label className="form-label">Tag</label>
        <select
         className="form-select"
         aria-label="Default select example"
         onChange={(event) => props.EventHandler2(event.target.value)}
         value={props.Value2}
        >
         {props.Dropdown1}
        </select>
       </div>
       {props.category === CATEGORYPREGANENTWOMEN ? (
        <div className="col-md-6 mb-3">
         <label className="form-label">{props.label}</label>
         <select
          className="form-select"
          aria-label="Default select example"
          onChange={(event) => props.EventHandler3(event.target.value)}
          value={props.Value3}
         >
          {props.Dropdown2}
         </select>
        </div>
       ) : (
        <div className="col-md-6">
         <label className="form-label">{props.label}</label>
         <select
          className="form-select"
          aria-label="Default select example"
          onChange={(event) => props.EventHandler3(event.target.value)}
          value={props.Value3}
         >
          {props.Dropdown2}
         </select>
        </div>
       )}

       <div className="col-md-6 mb-3">
        <label className="form-label">English Name</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={100}
         aria-describedby="emailHelp"
         placeholder="English Name"
         onChange={(event) => {
          props.EventHandler5(event.target.value);
         }}
         value={props.Value5}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Hindi Name</label>
        <input
         type="name"
         className="form-control"
         maxLength={100}
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="Hindi Name"
         onChange={(event) => {
          props.EventHandler6(event.target.value);
         }}
         value={props.Value6}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Activity Image English URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Activity Image English URL"
         onChange={(event) => {
          props.EventHandler7(event.target.value);
         }}
         value={props.Value7}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Activity Image Hindi URL</label>
        <input
         type="name"
         maxLength={300}
         className="form-control"
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="Activity Image Hindi URL"
         onChange={(event) => {
          props.EventHandler8(event.target.value);
         }}
         value={props.Value8}
        />
       </div>
      </div>
     </form>
    </div>
   </div>

   <div className="container">
    <div className="card notificationCard w-100">
     <form>
      <div className="row">
       <div className="col-md-6 mb-3">
        <label className="form-label">English Tip</label>
        <input
         type="name"
         maxLength={700}
         className="form-control"
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="English Tip"
         onChange={(event) => {
          props.EventHandler9(event.target.value);
         }}
         value={props.Value9}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Hindi Tip</label>
        <input
         type="name"
         className="form-control"
         maxLength={700}
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="Hindi Tip"
         onChange={(event) => {
          props.EventHandler10(event.target.value);
         }}
         value={props.Value10}
        />
       </div>
       <div className="col-md-6 mb-3">
        <label className="form-label">English Brainy Background</label>
        <input
         type="name"
         className="form-control"
         maxLength={500}
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="English Brainy Background"
         onChange={(event) => {
          props.EventHandler11(event.target.value);
         }}
         value={props.Value11}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Hindi Brainy Background</label>
        <input
         type="name"
         className="form-control"
         maxLength={500}
         id="exampleInputName1"
         aria-describedby="emailHelp"
         placeholder="Hindi Brainy Background"
         onChange={(event) => {
          props.EventHandler12(event.target.value);
         }}
         value={props.Value12}
        />
       </div>
       <div className="col-md-6 mb-3">
        <label className="form-label">Image Tip English URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Image Tip English URL"
         onChange={(event) => {
          props.EventHandler13(event.target.value);
         }}
         value={props.Value13}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Image Tip Hindi URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Image Tip Hindi URL"
         onChange={(event) => {
          props.EventHandler14(event.target.value);
         }}
         value={props.Value14}
        />
       </div>
       <div className="col-md-6 mb-3">
        <label className="form-label">Sound Tip English URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Sound Tip English URL"
         onChange={(event) => {
          props.EventHandler15(event.target.value);
         }}
         value={props.Value15}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Sound Tip Hindi URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         aria-describedby="emailHelp"
         maxLength={300}
         placeholder="Sound Tip Hindi URL"
         onChange={(event) => {
          props.EventHandler16(event.target.value);
         }}
         value={props.Value16}
        />
       </div>
       <div className="col-md-6 mb-3">
        <label className="form-label">Sound Brainy English URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Sound Brainy English URL"
         onChange={(event) => {
          props.EventHandler17(event.target.value);
         }}
         value={props.Value17}
        />
       </div>
       <div className="col-md-6">
        <label className="form-label">Sound Brainy Hindi URL</label>
        <input
         type="name"
         className="form-control"
         id="exampleInputName1"
         maxLength={300}
         aria-describedby="emailHelp"
         placeholder="Sound Brainy Hindi URL"
         onChange={(event) => {
          props.EventHandler18(event.target.value);
         }}
         value={props.Value18}
        />
       </div>

       <div className="col-md-12 d-flex justify-content-center mb-1 form-check mt-5">
        <div
         className="btn btn-primary downloadBtn nextButton"
         onClick={props.OnSubmitclick}
        >
         {props.loading ? (
          <div className="sendButtonLoader">
           <div className="preload-contentAreaSpecificLoader">
            <Loader type="bubble-top" bgColor={'#005190'} size={30} />
           </div>
          </div>
         ) : null}
         Submit
        </div>
       </div>
      </div>
     </form>
    </div>
   </div>
  </div>
 );
};
export default ActivityDetailFields;
