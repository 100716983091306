import React, { useEffect, useState } from 'react';
import '../assets/css/pagination.css';
import { getRequest, postRequest } from '../services/ApiService';
import DeleteModal from '../components/Modal/DeleteModal';
import { useHistory } from 'react-router-dom';
import ActivityDetailFields from '../components/activityDetailFields/ActivityDetailFields.jsx';

export default function AddActivity() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [category, setCategory] = useState('');
 const [tag, setTag] = useState([]);
 const [tagId, setTagId] = useState('');
 const [pregnancyMonths, setPregnancyMonths] = useState([]);
 const [pregnancyMonthsId, setPregnancyMonthsId] = useState('');
 const [ageGroup, setAgeGroup] = useState([]);
 const [ageGroupId, setAgeGroupId] = useState('');
 const [englishName, setEnglishName] = useState('');
 const [hindiName, setHindiName] = useState('');
 const [activityImageEnglish, setActivityImageEnglish] = useState('');
 const [activityImageHindi, setActivityImageHindi] = useState('');
 const [englishTip, setEnglishTip] = useState('');
 const [hindiTip, setHindiTip] = useState('');
 const [englishBrainy, setEnglishBrainy] = useState('');
 const [hindiBrainy, setHindiBrainy] = useState('');
 const [englishImageTip, setEnglishImageTip] = useState('');
 const [hindiImageTip, setHindiImageTip] = useState('');
 const [englishSoundTip, setEnglishSoundTip] = useState('');
 const [hindiSoundTip, setHindiSoundTip] = useState('');
 const [englishSoundBrainy, setEnglishSoundBrainy] = useState('');
 const [hindiSoundBrainy, setHindiSoundBrainy] = useState('');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const [showAlertModal, setShowAlertModal] = useState(false);
 const [showErrorAlertModal, setshowErrorAlertModal] = useState(false);
 const [loading, setLoading] = useState(false);

 function handleOnclick() {
  history.push('./activityContent');
 }
 const onCategoryChange = (categoryId) => {
  onDropDownTag(categoryId);
  setCategory(categoryId);
 };

 const onDropDownTag = async (id) => {
  const response = await postRequest('/activities/get/tags', {
   Category: id,
  });
  if (response) {
   console.log(response);
   response.result.shift();
   setTag(response.result);
   setTagId(response.result[0].tag_id);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 const onDropDownPregnancyMonths = async () => {
  const response = await getRequest('/admin/users/pregnancymonths/get');
  if (response) {
   console.log(response);
   setPregnancyMonths(response.result);
   setPregnancyMonthsId(response.result[0].pregnancy_id);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 const onDropDownAgeGroup = async () => {
  const response = await getRequest('/admin/users/agegroup/get');
  if (response) {
   console.log(response);
   setAgeGroup(response.result);
   setAgeGroupId(response.result[0].agegroup_id);
  } else {
   setshowErrorAlertModal(true);
  }
  return null;
 };

 useEffect(() => {
  setCategory('1');
  onDropDownTag('1');
  onDropDownPregnancyMonths();
  onDropDownAgeGroup();
 }, []);

 const onSubmit = async () => {
  setLoading(true);
  if (
   category &&
   tagId &&
   englishName &&
   hindiName &&
   activityImageEnglish &&
   activityImageHindi &&
   englishTip &&
   englishBrainy &&
   englishImageTip &&
   englishSoundTip &&
   englishSoundBrainy &&
   hindiTip &&
   hindiBrainy &&
   hindiImageTip &&
   hindiSoundTip &&
   hindiSoundBrainy
  ) {
   const response = await postRequest('/admin/activities/add', {
    Category: category,
    TagId: tagId,
    Title_En: englishName,
    Title_H: hindiName,
    ActivityImage_En: activityImageEnglish,
    ActivityImage_H: activityImageHindi,
    AgeGroupId: category === '1' ? pregnancyMonthsId : ageGroupId,
    Task_En: englishTip,
    Explanation_En: englishBrainy,
    Image_En: englishImageTip,
    Sound_Task_En: englishSoundTip,
    Sound_Explanation_En: englishSoundBrainy,
    Task_H: hindiTip,
    Explaination_H: hindiBrainy,
    Image_H: hindiImageTip,
    Sound_Task_H: hindiSoundTip,
    Sound_Explanation_H: hindiSoundBrainy,
   });
   if (response) {
    console.log(response);
    setLoading(false);

    setShowSuccessModal(true);
   } else {
    setshowErrorAlertModal(true);
   }
  } else setLoading(false);
  setShowAlertModal(true);
 };

 return (
  <>
   <ActivityDetailFields
    EventHandler1={onCategoryChange}
    EventHandler2={setTagId}
    EventHandler3={category === '1' ? setPregnancyMonthsId : setAgeGroupId}
    EventHandler5={setEnglishName}
    EventHandler6={setHindiName}
    EventHandler7={setActivityImageEnglish}
    EventHandler8={setActivityImageHindi}
    EventHandler9={setEnglishTip}
    EventHandler10={setHindiTip}
    EventHandler11={setEnglishBrainy}
    EventHandler12={setHindiBrainy}
    EventHandler13={setEnglishImageTip}
    EventHandler14={setHindiImageTip}
    EventHandler15={setEnglishSoundTip}
    EventHandler16={setHindiSoundTip}
    EventHandler17={setEnglishSoundBrainy}
    EventHandler18={setHindiSoundBrainy}
    Dropdown1={tag.map((x, y) => (
     <option key={x.tag_id} value={x.tag_id}>
      {x.tag_en}
     </option>
    ))}
    Dropdown2={
     category === '1'
      ? pregnancyMonths.map((x, y) => (
         <option key={x.pregnancy_id} value={x.pregnancy_id}>
          {x.pregnancy_months}
         </option>
        ))
      : ageGroup.map((x, y) => (
         <option key={x.agegroup_id} value={x.agegroup_id}>
          {x.range}
         </option>
        ))
    }
    label={category === '1' ? 'Pregnancy Months' : 'Age Group'}
    OnSubmitclick={() => onSubmit()}
    loading={loading}
   />
   {showAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowAlertModal(false)}
      successText="Please enter all input fields."
      ClickOk={() => setShowAlertModal(false)}
     />
    </>
   ) : null}
   {showSuccessModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setShowSuccessModal(false)}
      successText="Activity added successfully"
      ClickOk={() => handleOnclick()}
     />
    </>
   ) : null}
   {showErrorAlertModal ? (
    <>
     <DeleteModal
      isSuccessModal={true}
      Onclick={() => setshowErrorAlertModal(false)}
      successText="Some error Occured"
      ClickOk={() => setshowErrorAlertModal(false)}
     />
    </>
   ) : null}
  </>
 );
}
