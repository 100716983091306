import React, { useEffect, useMemo, useState } from 'react';
import UserIcon from '../assets/images/userIcon.png';
import { PieChart } from 'react-minimal-pie-chart';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getRequest, postRequest } from '../services/ApiService';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import onClickSelect from '../../src/assets/JsonData/click_select.json';
import Loader from 'react-js-loader';
import {
 CATEGORYPREGANENTWOMEN,
 ENDINDEX,
 STARTINDEX,
} from '../libs/Constants';
import LineChart from 'react-linechart';
import moment from 'moment';
import Button from '../components/buttons/Button';
import TextInput from '../components/inputFields/TextInput';

export default function Analytics() {
 const history = useHistory();
 if (!sessionStorage.getItem('userId')) history.push('/');
 const [dataLoader, setDataLoader] = useState(false);
 const [pieChartIndex, setPieChartIndex] = useState(-1);
 const [pieChartData, setPieChartData] = useState([
  { title: 'one', value: 50, color: '#FFE500' },
  { title: 'two', value: 5, color: '#001aff' },
  { title: 'three', value: 15, color: '#00E0FF' },
  { title: 'four', value: 30, color: '#FF5C00' },
 ]);

 const [totalRegistration, setTotalRegistration] = useState({display:false, data:{}});
 const [lineChartData, setLineChartData] = useState([
  // {
  //  color: '#e2504d',
  //  points: [
  //   { x: 1, y: 2 },
  //   { x: 2, y: 4 },
  //   { x: 3, y: 0 },
  //   { x: 4, y: -2 },
  //   { x: 6, y: 3 },
  //   { x: 7, y: -3 },
  //  ],
  // },
  {
   color: 'steelblue',
   points: [
    { x: 1, y: 3 },
    { x: 4, y: 5 },
    { x: 2, y: 0 },
    { x: 5, y: -1 },
    { x: 6, y: 2 },
    { x: 7, y: -2 },
   ],
  },
 ]);
 const [userData, setUserData] = useState({
  notYetStarted: 0,
  totalUsers: 0,
  likedContent: [],
  trendingQuestions: [],
 });
 const [userViews, setUserViews] = useState({
  count: 0,
  taskDone: 0,
 });
 const dateObj = new Date();
 const newDate = moment(dateObj).format('YYYY-MM-DD');

 const [userDate, setUserDate] = useState({
  from: '2022-09-23',
  to: newDate,
 });

 const [userYear, setUserYear] = useState();

 //  const onClickSelect = [
 //   {
 //    value: 1,
 //    option: 2022,
 //   },
 //   {
 //    value: 2,
 //    option: 2023,
 //   },
 //   {
 //    value: 3,
 //    option: 2024,
 //   },
 //  ];

 const getUserData = async () => {
  setDataLoader(true);

  console.log(newDate);
  console.log(userDate.from < '2022-09-23');
  const response = await postRequest('admin/analytics/user', {
   FromDate:
    userDate.from > '2022-09-23'
     ? moment(userDate.from).format('YYYY-MM-DD')
     : moment('2022-09-23').format('YYYY-MM-DD'),
   ToDate: userDate.to
    ? moment(userDate.to).format('YYYY-MM-DD')
    : moment(newDate).format('YYYY-MM-DD'),
  });

  const responseViews = await postRequest('admin/analytics/get', {
   StartDate:
    userDate.from > '2022-09-23'
     ? moment(userDate.from).format('YYYY-MM-DD')
     : moment('2022-09-23').format('YYYY-MM-DD'),
   EndDate: userDate.to
    ? moment(userDate.to).format('YYYY-MM-DD')
    : moment(newDate).format('YYYY-MM-DD'),
   DimensionsName: 'eventName',
   MetricsName: 'eventCount',
  });
  if (responseViews.length > 0 && responseViews) {
   setUserViews({
    count: responseViews[0].metricValues[0].value,
    taskDone: responseViews[3].metricValues[0].value,
   });
  }
  if (response) {
   console.log(JSON.stringify(response.result));
   console.log(JSON.stringify(response.result[2]));

   setUserData({
    notYetStarted: response.result[5][0].notyetstarted,
    totalUsers: response.result[0][0].total_users,
    likedContent: response.result[8],
    trendingQuestions: response.result[9],
   });
   setPieChartData([
    {
     title: 'Mothers' + ' - ' + response.result[1][0]?.total_mothers + ' ',
     value: response.result[1][0].total_mothers,
     color: '#ffd900',
    },
    {
     title: 'Fathers' + ' - ' + response.result[2][0]?.total_fathers + ' ',
     value: response.result[2][0].total_fathers,
     color: '#9E00FF',
    },
    {
     title:
      'Pregnant Women' +
      ' - ' +
      response.result[3][0].total_pregnantwomen +
      ' ',
     value: response.result[3][0].total_pregnantwomen,
     color: '#00E0FF',
    },
    {
     title:
      'Other Healthcare Professionals' +
      ' - ' +
      response.result[14][0]?.total_HealthWorkerOther +
      ' ',
     value: response.result[14][0].total_HealthWorkerOther,
     color: '#a6ff00',
    },
    {
     title: 'ASHA' + ' - ' + response.result[12][0]?.total_ASHA + ' ',
     value: response.result[12][0].total_ASHA,
     color: '#ffae00',
    },
    {
     title: 'ANM' + ' - ' + response.result[11][0]?.total_ANM + ' ',
     value: response.result[11][0].total_ANM,
     color: '#ddff0097',
    },
    {
     title: 'Doctor' + ' - ' + response.result[13][0]?.total_Doctor + ' ',
     value: response.result[13][0].total_Doctor,
     color: '#2200ff',
    },
    {
     title:
      'Others' +
      ' - ' +
      (response.result[0][0].total_users -
       (response.result[1][0].total_mothers +
        response.result[2][0].total_fathers +
        response.result[3][0].total_pregnantwomen +
        response.result[4][0].total_healthworker)) +
      ' ',
     value:
      response.result[0][0].total_users -
      (response.result[1][0].total_mothers +
       response.result[2][0].total_fathers +
       response.result[3][0].total_pregnantwomen +
       response.result[4][0].total_healthworker),
     color: '#FF5C66',
    },
   ]);
   setDataLoader(false);
  } else {
   setDataLoader(false);
  }
 };
 console.log("rndcn",totalRegistration)

const onGroupedHover=(point) =>{
  setTotalRegistration({display:true, data:{point}})
  console.log("point",point,lineChartData)
  return `<b>No. of Registrations: </b>${point.y}`;
}
 const getUserDataYearly = async (selectedYear) => {
  setDataLoader(true);

  let currentYear = selectedYear;
  const firstDay = new Date(currentYear, 0, 1);
  const lastDay = new Date(currentYear, 11, 31);
  console.log(
   moment(firstDay).format('YYYY-MM-DD'),
   moment(lastDay).format('YYYY-MM-DD')
  );

  const responseUsers = await postRequest('admin/analytics/get', {
   StartDate:
    currentYear == '2022'
     ? '2022-09-23'
     : moment(firstDay).format('YYYY-MM-DD'),
   EndDate: moment(lastDay).format('YYYY-MM-DD'),
   DimensionsName: 'month',
   MetricsName: 'newUsers',
  });

  // console.log('gsagd', response[[0]]);

  if (responseUsers.length > 0 && responseUsers) {
   const lineChartPoints = responseUsers.map((item, i) => {
    return {
     x: item.dimensionValues[0].value,
     y: item.metricValues[0].value,
    };
   });
   console.log('lineChartpoints', JSON.stringify(lineChartPoints));
   lineChartPoints.sort(function (a, b) {
    return a.x - b.x;
   });
   console.log('lineChartpoints', JSON.stringify(lineChartPoints));

   setLineChartData([
    {
     color: 'steelblue',
     points: lineChartPoints,
    },
   ]);
   //  console.log(document.getElementsByClassName('tick')[0].lastChild.innerHTML);
   //  console.log(
   //   document.getElementsByClassName('tick')[0].lastChild.textContent
   //  );
   setDataLoader(false);
   for (
    let index = 0;
    index < document.getElementsByClassName('tick').length;
    index++
   ) {
    const element =
     document.getElementsByClassName('tick')[index].lastChild.innerHTML;
    console.log('element', element);
    if (element == '1')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'January';
    if (element == '2')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'February';
    if (element == '3')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'March';
    if (element == '4')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'April';
    if (element == '5')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'May';
    if (element == '6')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'June';
    if (element == '7')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'July';
    if (element == '8')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'August';
    if (element == '9')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'September';
    if (element == '10')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'October';
    if (element == '11')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'November';
    if (element == '12')
     document.getElementsByClassName('tick')[index].lastChild.textContent =
      'December';
   }

   //  document.getElementsByClassName('tick')[0].lastChild.textContent =
   //   'september';
  } else {
   setLineChartData([]);
   setDataLoader(false);
  }
 };
 useMemo(() => {
  getUserData();
  getUserDataYearly('2022');
 }, []);
 return (
  <div>
   <div className="topBarContainer">
    <div className="container">
     <div className="row">
      <div className="col-md-12 searchContainer">
       {' '}
       <div className="activeUserCard fromTodate">
        <div className="imageContainer">
         <div className="col-md-4 mb-4">
          <label for="" class="form-label" style={{ color: 'white' }}>
           From
          </label>
          <TextInput
           dateInputField={true}
           DatePicker={true}
           Value={userDate.from}
           OnChange={(event) =>
            setUserDate({ ...userDate, from: event.target.value })
           }
          />
         </div>
         <div className="col-md-4 mb-4">
          <label for="" class="form-label" style={{ color: 'white' }}>
           To
          </label>
          <TextInput
           dateInputField={true}
           DatePicker={true}
           Value={userDate.to}
           OnChange={(event) =>
            setUserDate({ ...userDate, to: event.target.value })
           }
          />
         </div>
         <div style={{ width: '100%' }}>
          <Button
           buttonTxt="Submit"
           OnClick={() => {
            getUserData();
           }}
          />
         </div>
        </div>
        {/* <span className="count">{userData.totalUsersDatewise}</span> */}
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="container">
    <div className="row">
     <div className="col-md-7">
      <div className="userStatusConatiner">
       <div className="activeUserCard">
        <div className="imageContainer">
         <img src={UserIcon} alt="" />
         <p className="noOfUser">
          No. of <br />
          Registrations
         </p>
        </div>
        <span className="count">{userData.totalUsers}</span>
       </div>
       <div className="activeUserCard">
        <div className="imageContainer">
         <img src={UserIcon} alt="" />
         <p className="noOfUser">
          No. of <br />
          Activity Views
         </p>
        </div>
        <span className="count">{userViews.count}</span>
       </div>
       <div className="activeUserCard">
        <div className="imageContainer">
         <img src={UserIcon} alt="" />
         <p className="noOfUser">
          No. of <br />
          Task done
         </p>
        </div>
        <span className="count">{userViews.taskDone}</span>
       </div>
       {/* <div className="userStatus weeklyCard">
        <div className="weeklyCardTopBar">
         <p>User Status</p>
        </div>
        <div className="progressBarMainContainer">
         <div className="progressBarContainer">
          <CircularProgressbar
           value={userData.notYetStarted.toFixed(2)}
           className="firstProgressBar"
          />
          <CircularProgressbar
           value={(100 - userData.notYetStarted).toFixed(2)}
           className="secondProgressBar"
          />
         </div>
         <div className="progressBarResult">
          <p className="notYetStarted">{userData.notYetStarted.toFixed(2)}%</p>
          <span className="notYetStartedSpan">not yet started</span>

          <p className="inProgress">
           {(100 - userData.notYetStarted).toFixed(2)}%
          </p>
          <span className="inProgressSpan">in progress</span>
         </div>
        </div>
       </div> */}
       <div className="weeklyContainer">
        <div className="weeklyCard">
         <div className="weeklyCardTopBar">
          <p>Top 5 trending questions of the month</p>
         </div>
         <ul className="weeklyCardList">
          {dataLoader ? (
           <div className="DataLoader">
            <div className="preload-contentAreaSpecificLoader">
             <Loader type="bubble-top" bgColor={'#005190'} size={50} />
            </div>
           </div>
          ) : null}
          {userData.trendingQuestions.length > 0 ? (
           <>
            {userData.trendingQuestions.map((item, i) => {
             return (
              <>
               {' '}
               <li>
                <Link to={{ pathname: './ViewForum', details: item }}>
                 <div className="linkForAvtivityDetail">
                  Q{i + 1}:{' '}
                  {item.type === 'audio' ? (
                   <div className="audioPlayer1">
                    <ReactAudioPlayer
                     src={item.question}
                     controls
                     controlsList="nodownload"
                    />
                   </div>
                  ) : (
                   item.question
                  )}
                 </div>
                 <div>
                  <span>{item.total_responses} responses</span>
                 </div>
                </Link>
               </li>
              </>
             );
            })}
           </>
          ) : dataLoader ? null : (
           <div className="no-data">NO DATA FOUND</div>
          )}
         </ul>
        </div>
       </div>
      </div>
     </div>
     <div className="col-md-5">
      <div className="resourceAnalysis weeklyCard">
       <div className="weeklyCardTopBar">
        <p>Top 5 liked content of the month</p>
       </div>
       <ul className="weeklyCardList resourceAnalysisList">
        {dataLoader ? (
         <div className="DataLoader">
          <div className="preload-contentAreaSpecificLoader">
           <Loader type="bubble-top" bgColor={'#005190'} size={50} />
          </div>
         </div>
        ) : null}
        {userData.likedContent.length > 0 ? (
         <>
          {userData.likedContent.map((item) => {
           return (
            <>
             <li>
              <Link
               to={{ pathname: './ActivityDetail', id: item.activity_id }}
               className="linkForAvtivityDetail"
              >
               <img src={item.activity_image_en} className="userImg"></img>
               <div className="activityTitle">
                <p>{item.title_en}</p>

                <span style={{ display: 'flex' }}>
                 {item.total_liked} likes
                </span>
               </div>
              </Link>
             </li>
            </>
           );
          })}
         </>
        ) : dataLoader ? null : (
         <div className="no-data">NO DATA FOUND</div>
        )}
       </ul>
      </div>
      <div className="weeklyCard">
       <div className="weeklyCardTopBar">
        <p>Users</p>
       </div>
       <div className="pieChartContainer">
        <PieChart
         onMouseOver={(e, index) => setPieChartIndex(index)}
         onMouseOut={() => setPieChartIndex(-1)}
         data={pieChartData}
         className="pieChartData"
        />
        <div className="pieChartCircleContainer">
         {pieChartData.map((item, index) => {
          return (
           <p
            className="countingContainer"
            style={{
             textDecoration:
              index === pieChartIndex ? 'underline solid red 3px' : 'none',
            }}
           >
            <p
             className="circle"
             style={{
              background: item.color,
             }}
            ></p>{' '}
            {item.title} (
            {((item.value * 100) / userData.totalUsers).toFixed(0)}
            %)
           </p>
          );
         })}
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="weeklyCard lineChart_card ">
    <div class="weeklyCardTopBar">
     <p>Trend of registration month wise</p>

     <select
      name="select"
      // class="form-control"
      id="dropdownYear"
      style={{ width: 120 }}
      onChange={(e) => {
       console.log('index', e.target.value);
       getUserDataYearly(e.target.value);
      }}
     >
      {onClickSelect.map((item, index) => {
       return (
        <option
         value={item.option}
         selected={item.option === '2022' ? 'selected' : null}
        >
         {item.option}
        </option>
       );
      })}
      {/* <option value="2023">2023</option>
      <option value="2024">2024</option>
      <option value="2025">2025</option>
      <option value="2026">2026</option>
      <option value="2027">2027</option> */}
     </select>

     {/* <YearRangePicker onSelect={() => getUserDataYearly()} /> */}
    </div>

    <div style={{ paddingLeft: '20px', paddingBottom: '12px' }}>
     {dataLoader ? (
      <div className="DataLoader">
       <div className="preload-contentAreaSpecificLoader">
        <Loader type="bubble-top" bgColor={'#005190'} size={50} />
       </div>
      </div>
     ) : null}
     {totalRegistration.display&& <div className="ToolTipShow">
      <span>Month:{totalRegistration.data.point.x}</span>
      <span>No. of Registration: {totalRegistration.data.point.y}</span>
     </div>}
    
     {lineChartData.length > 0 ? (
      <LineChart
       data={lineChartData}
       yMin={0}
       yMax={3000}
       onPointHover={(point)=>onGroupedHover(point)}
       xLabel="Months"
       yLabel="No. of Users"
       showLegends={false}
       interpolate="cardinal"
       tooltipClass={'svg-line-chart-tooltip'}
      />
     ) : (
      'No Data found'
     )}
    </div>
   </div>
  </div>
 );
}
