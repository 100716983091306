import React from 'react';
import { Link } from 'react-router-dom';
import { ENGLISH } from '../../libs/Constants';
import { CATEGORYPREGANENTWOMEN } from '../../libs/Constants';
export default function ActivityCard(props) {
 return (
  <div className="card activityCard p-3">
   <div className="row">
    <div className="col-md-4">
     <img
      src={props.language === ENGLISH ? props.image_e : props.image_h}
      style={{ width: '240px' }}
      className="activityCardImage"
      alt=""
     />
    </div>
    <div className="col-md-3">
     <h6 className="category">Category</h6>
     <p className="sub mb-5">
      {props.language === ENGLISH ? props.category_e : props.category_h}
     </p>
     <h6 className="category">Heading</h6>
     <p className="sub">
      {' '}
      {props.language === ENGLISH ? props.title_e : props.title_h}
     </p>
    </div>
    <div className="col-md-2">
     <h6 className="category">Tag</h6>
     <p className="sub">
      {' '}
      {props.language === ENGLISH ? props.tag_e : props.tag_h}
     </p>
    </div>
    <div className="col-md-3 d-flex align-items-center flex-column">
     <h6 className="category">Age Group</h6>
     <p className="sub mb-5">
      {props.agegroup} {props.language === ENGLISH ? ' Months' : ' महीने'}
     </p>
     {props.activityViews ? (
      <>
       <h6 className="category"> Users Completed</h6>
       <p className="sub mb-5">{props.activityViews} </p>
      </>
     ) : null}

     {props.actionType === 'Update' ? (
      <Link to={{ pathname: props.path, details: props }} className="pt-5">
       <button href="replace" className="downloadBtn updateBtn">
        {props.actionType}
       </button>
      </Link>
     ) : (
      <Link to={{ pathname: props.path, id: props.id }} className="pt-5">
       {' '}
       <button href="replace" className="downloadBtn updateBtn">
        {props.actionType}
       </button>
      </Link>
     )}
    </div>
   </div>
  </div>
 );
}
