import {
 BASE_URL,
 CONTENT_TYPE,
 DELETE_REQUEST,
 GET_REQUEST,
 PATCH_REQUEST,
 POST_REQUEST,
 PUBLIC_TOKEN,
} from '../libs/Constants';
const HEADERS = {
 Accept: CONTENT_TYPE,
 'Content-Type': CONTENT_TYPE,
 'x-access-token': PUBLIC_TOKEN,
};
export const postRequest = async (Url, BODY) => {
 try {
  const response = await fetch(BASE_URL + Url, {
   method: POST_REQUEST,
   headers: HEADERS,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  if (json.error) {
   alert('some error occured');
  } else {
   return json;
  }
  console.log(json);
 } catch (error) {
  console.log(error);
  return error;
 }
};
export const getRequest = async (Url) => {
 try {
  const response = await fetch(BASE_URL + Url, {
   method: GET_REQUEST,
   headers: HEADERS,
  });
  const json = await response.json();
  if (json.error) {
   alert('some error occured');
  } else {
   return json;
  }
  console.log(json);
 } catch (error) {
  console.log(error);
  return error;
 }
};

export const deleteRequest = async (Url, BODY) => {
 try {
  const response = await fetch(BASE_URL + Url, {
   method: DELETE_REQUEST,
   headers: HEADERS,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  if (json.error) {
   alert('some error occured');
  } else {
   return json;
  }
  console.log(json);
 } catch (error) {
  console.log(error);
  return error;
 }
};
export const patchRequest = async (Url, BODY) => {
 try {
  const response = await fetch(BASE_URL + Url, {
   method: PATCH_REQUEST,
   headers: HEADERS,
   body: JSON.stringify(BODY),
  });
  const json = await response.json();
  if (json.error) {
   alert('some error occured');
  } else {
   return json;
  }
  console.log(json);
 } catch (error) {
  console.log(error);
  return error;
 }
};
